
import Vue from "vue";
import { RunnitsCRUDMixin } from "@/mixins";

export default Vue.extend({
	name: "TrainModelCard",
	mixins: [
		RunnitsCRUDMixin
	],
	props: {
		title: { type: String, default: "" },
		icon: { type: String, default: null },
		description: { type: String, default: "" },
		selected: { type: Boolean, default: false },
		timeEstimate: { type: String, default: "" },
		comingSoon: { type: Boolean, default: false }
	},
	data() {
		return {
			typeSelected: this.selected
		};
	},
	computed: {
		isSelected() {
			return this.selected;
		}
	},
	methods: {
		onClick() {
			if (this.comingSoon) {
				return;
			}

			this.$emit("onClick");
		}
	}
});
