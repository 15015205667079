
import Vue from 'vue';
import RunnitDialog from './RunnitDialog.vue';
import { mapState } from "vuex";
import { ROUTER } from "@/router/constants";

export default Vue.extend({
	name: 'RunnitModelTrainingDialog',
	props: {
		open: { type: Boolean, default: false },
	},
	computed: {
		...mapState([
			'user',
			'team',
			'runnitState',
			'modelsState'
		]),
	},
	methods: {
		goToModels () {
			this.routerPush(this.$route, this.$router, {
				name: ROUTER.RUNNITS_MODELS_LIST,
			});
		},
	},
	components: {
        RunnitDialog,
	},
});
