import { VSlider } from 'vuetify/lib/components/VSlider';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"rd-base-slider"},[_c('div',{staticClass:"base-slider-container"},[_c(VSlider,_vm._b({style:({
				padding: _vm.incrementerPadding,
			}),attrs:{"thumb-color":"white"},on:{"change":_vm.onIncrement,"input":_vm.onInput}},'v-slider',_vm.$attrs,false)),_vm._t("between-slider-incrementer"),_c('div',[_c('number-with-incrementer',_vm._b({style:({
					padding: _vm.incrementerPadding,
				}),attrs:{"disabled":!!_vm.$attrs.disabled,"condensed":_vm.condensedIncrementer,"value":_vm.visibleValue,"number-min-width":_vm.numberMinWidth},on:{"input":_vm.onIncrement}},'number-with-incrementer',{
					...(+_vm.$attrs.step > 0 && {
						step: _vm.$attrs.step,
					}),
					...(!_vm._isNil(_vm.$attrs.min) && !_vm._isNil(_vm.$attrs.max) && {
						min: _vm.$attrs.min,
						max: _vm.$attrs.max,
					}),
				},false))],1)],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }