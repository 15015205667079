// Used this as a template: https://github.com/fabricjs/fabricjs.github.io/blob/main/src/content/demo/custom-filter-swap-color/colorSwapFilter.ts
import { T2DPipelineState, TWebGLUniformLocationMap, filters } from 'fabric';

type SetColorFilterProps = {
	color: string; // 'rgba( #, #, #, #)'
};

/**
 * SetColorFilter filter class
 * change all non-transparent pixels to the specified color
 */
export class SetColorFilter extends filters.BaseFilter<'SetColorFilter', SetColorFilterProps> {

	static type = 'SetColorFilter';

	static defaults = {
		color: 'rgba(255, 255, 255, 0.75)',
	};

	declare color: SetColorFilterProps['color'];

	static uniformLocations = ['uColor'];

	fragmentSource = `
		precision highp float;
		uniform sampler2D uTexture;
		uniform vec4 uColor; // RGBA color
		varying vec2 vTexCoord;
		void main() {
			vec4 originalColor = texture2D(uTexture, vTexCoord);
			if (originalColor.a > 0.0) { // if not fully transparent
				gl_FragColor = vec4(uColor.rgb, uColor.a);
			} else {
				gl_FragColor = originalColor; // keep transparent pixels unchanged
			}
		}`;

	protected getFragmentSource (): string {
		return this.fragmentSource;
	}

	/**
	 * Apply the SetColorFilter operation to a Uint8ClampedArray representing the pixels of an image.
	 *
	 * @param {Object} options
	 * @param {ImageData} options.imageData The Uint8ClampedArray to be filtered.
	 */
	applyTo2d (options: T2DPipelineState) {
		const { imageData: { data } } = options;
		const [r, g, b, a] = this.color.match(/\d+(\.\d+)?/g)?.map(Number) || [255, 255, 255, 0.75];

		for (let i = 0; i < data.length; i += 4) {
			const rChannel = i;
			const gChannel = i + 1;
			const bChannel = i + 2;
			const aChannel = i + 3;

			if (data[aChannel] > 0) { // if not fully transparent
				data[rChannel] = r; // Red
				data[gChannel] = g; // Green
				data[bChannel] = b; // Blue
				data[aChannel] = a; // Alpha
			}
		}
	}

	/**
	 * Send data from this filter to its shader program's uniforms.
	 *
	 * @param {WebGLRenderingContext} gl The GL canvas context used to compile this filter's shader.
	 * @param {Object} uniformLocations A map of string uniform names to WebGLUniformLocation objects
	 */
	sendUniformData (
		gl: WebGLRenderingContext,
		uniformLocations: TWebGLUniformLocationMap,
	) {
		const [r, g, b, a] = this.color.match(/\d+(\.\d+)?/g)?.map(Number) || [255, 255, 255, 0.75];
		gl.uniform4f(uniformLocations.uColor, r, g, b, a);
	}
}
