
import Vue from 'vue';
import {mapActions, mapGetters, mapState} from 'vuex';
import { RunnitUpload } from '@run-diffusion/shared';
import { SNACKBAR_STATUS } from '@/constants/constants';

export default Vue.extend({
	name: 'ModelUpload',
	props: {
		disabled: { type: Boolean, default: false },
	},
	data () {

		return {
			FILE_SIZE_LIMIT: 53687091200, // 50 GB in bytes

			isBeingDraggedOver: false,
		};
	},
	computed: {
		...mapState([
			'runnitState',
			'user',
		]),
		...mapGetters('modelUpload', [
			'getUploading',
		])
	},
	watch: {
	},
	methods: {
		...mapActions([
			'updateRunnitState',
			'updateSnackbar',
		]),
		onUploadComplete (val: RunnitUpload) {
			this.$emit('on-upload-complete', val);
		},
		handleDragOver (ev) {
			if (this.disabled) return;
			this.isBeingDraggedOver = true;
			ev.preventDefault();
		},
		handleDragLeave (ev) {
			if (this.disabled) return;
			this.isBeingDraggedOver = false;
			ev.preventDefault();
		},
		async handleDrop (ev) {
			if (this.disabled) return;
			this.isBeingDraggedOver = false;
			let dt = ev.dataTransfer;
			let files = dt.files;
			await this.handleFiles(files);
		},
		async onHiddenFileInputChange (ev) {
			this.isBeingDraggedOver = false;
			await this.handleFiles(ev.target.files);

			// Clear the selected file from the input
			this.$refs.fileInput.value = ''; // Reset the value of the input element
		},
		openFileDialog () {
			if (this.disabled) return;
			this.$refs.fileInput.click();
		},
		async handleFiles (files) {
			this.isBeingDraggedOver = false;

			// Pull off first file
			const file = this._get([...files], '[0]') || null;
			if (!file) return;

			// Size check files
			if (file.size >= this.FILE_SIZE_LIMIT) {
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: `Image to upload must be less than 50 gigabytes`,
					show: true,
				});
				return;
			}
			this.$emit('input', file);
		},
	},
	components: {
	},
});
