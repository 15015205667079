import modelUploader from "@/utils/modelUploader";
import {SNACKBAR_STATUS} from "@/constants/constants";
import {db} from "@/firebase";
import {
	Avatar,
	Model,
	MODEL_CREATE_METHOD
} from "@run-diffusion/shared";
import { v4 as uuidv4 } from 'uuid';

interface UploadRecord {
	id: string;
	model: Model;
	avatar: Avatar;
	isUploading: boolean;
	progress: number;
	error: string | null;
}

interface UploadState {
	uploads: Record<string, UploadRecord>;
}

export default {
	namespaced: true,
	state: {
		uploads: {},
	} as UploadState,
	mutations: {
		ADD_UPLOAD(state: UploadState, upload: UploadRecord) {
			state.uploads = {
				...state.uploads,
				[upload.id]: upload,
			}
		},
		SET_UPLOADING(state: UploadState, { id, isUploading }: { id: string; isUploading: boolean }) {
			if (state.uploads[id]) {
				state.uploads = {
					...state.uploads,
					[id]: {
						...state.uploads[id],
						isUploading
					}
				}
			}
		},
		SET_PROGRESS(state: UploadState, { id, progress }: { id: string; progress: number }) {
			if (state.uploads[id]) {
				state.uploads = {
					...state.uploads,
					[id]: {
						...state.uploads[id],
						progress
					}
				}
			}
		},
		SET_ERROR(state: UploadState, { id, error }: { id: string; error: string | null }) {
			if (state.uploads[id]) {
				state.uploads = {
					...state.uploads,
					[id]: {
						...state.uploads[id],
						error
					}
				}
			}
		},

	},
	actions: {

		async startUpload({ commit, dispatch, rootGetters}, { model, file, avatar }: { model: Model, file: File, avatar: Avatar }) {
			const uploadId = uuidv4();
			commit('ADD_UPLOAD', {
				id: uploadId,
				model,
				avatar,
				isUploading: true,
				progress: 0,
				error: null,
			});

			try {
				const uploadedFile = await modelUploader(model.userId, model.teamId, file, (progress: number) => {
					commit('SET_PROGRESS', { id: uploadId, progress });
				});

				if (uploadedFile) {
					await db.collection('models').add({
						...model,
						file: uploadedFile,
						createMethod: MODEL_CREATE_METHOD.UPLOAD,
					});

					await dispatch('updateSnackbar', {
						status: SNACKBAR_STATUS.SUCCESS,
						message: 'Model uploaded successfully',
						show: true,
					}, { root: true });
				} else {
					await dispatch('updateSnackbar', {
						status: SNACKBAR_STATUS.WARN,
						message: `Model (${file.name}) has already been uploaded`,
						show: true,
					}, { root: true });
				}

			} catch (e: any) {
				commit('SET_ERROR', { id: uploadId, error: e.message });
				console.error(uploadId, e);
				await dispatch('updateSnackbar', {
					status: SNACKBAR_STATUS.ERROR,
					message: `Error uploading model`,
					show: true,
				}, { root: true });
			} finally {
				commit('SET_UPLOADING', { id: uploadId, isUploading: false });
			}

			return uploadId;
		},
	},
	getters: {
		allUploads: (state: UploadState) => Object.values(state.uploads),
		getUploading: (state: UploadState) => Object.values(state.uploads).filter(upload => upload.isUploading).sort((a, b) => a.model.createdAt > b.model.createdAt ? 1 : -1),
	},
};
