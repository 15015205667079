
import Vue from 'vue';

export default Vue.extend({
	props: {
		iconName: {
			type: String,
			default: 'Background Primary Color',
		},
		width: {
			type: [Number, String],
			default: 28,
		},
		height: {
			type: [Number, String],
			default: 28,
		},
		viewBox: {
			type: String,
			default: '0 0 28 28',
		},
		bevel: {
			type: Number,
			default: 4,
		},
		backgroundColor: {
			type: String,
			default: '#000000',
		},
		backgroundBorderColor: {
			type: String,
			default: '#000000',
		},
		primaryColor: {
			type: String,
			default: '#FFFFFF',
		},
		primaryBorderColor: {
			type: String,
			default: '#000000',
		},
	},
});
