import Vue from 'vue';

export const ImageUploadMixin = Vue.extend({
	methods: {
		async getImageMetaData (file): Promise<{ name: string, size: number, contentType: string, width: number, height: number }> {
			return new Promise((resolve, reject) => {
				const image = new Image();
				const tempUrl = URL.createObjectURL(file); // create a temporary URL for the image
				image.src = tempUrl;

				image.onload = () => {
					resolve({
						name: file.name,
						size: file.size,
						contentType: file.type,
						width: image.width,
						height: image.height,
					});
					URL.revokeObjectURL(tempUrl);
				}
				image.onerror = () => {
					reject(new Error('Failed to load image for metaData'));
					URL.revokeObjectURL(tempUrl);
				}

			});
		},
	}
});