
import Vue from 'vue';

export default Vue.extend({
	name: 'RunnitModels',
	computed: {},
	components: {},
	props: {},
	data () {
		return {};
	},
	methods: {}
});
