
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import { functions } from '@/firebase';
import {
	SNACKBAR_STATUS,
	TEXT_FIELD_MAX_LENGTH,
	SHOW_TEXT_FIELD_COUNTER_AT,
} from '@/constants/constants';
import GlassButton from '@/components/base/GlassButton.vue';
import RunnitMenuOrBottomSheet from '@/views/Runnits/base/RunnitMenuOrBottomSheet.vue';
import ComplexBackgroundSettingMenu from '@/components/designElements/ComplexBackgroundSettingMenus.vue';
import { PROMPT_MAKER_ERROR_CODES } from '@run-diffusion/shared';

export default Vue.extend({
	name: 'PromptGenerator',
	props: {
		promptValue: { type: String, default: null },
		promptField: { type: Object, required: true },
	},
	data () {
		return {
			TEXT_FIELD_MAX_LENGTH,
			SHOW_TEXT_FIELD_COUNTER_AT,
			loadingPrompts: false,
			numGenerations: 1,

			generatedPrompts: [],
			errorGeneratingPrompts: false,
		};
	},
	watch: {
		'$store.state.triggers.fireInitialPromptGenerationTrigger': {
			immediate: false,
			async handler (newVal: number, oldVal: number) {
				if (
					newVal &&
					newVal !== oldVal &&
					!this.generatedPrompts.length &&
					this.promptValue
				) {
					await this.onClickGeneratePrompts();
				}
			},
		},
	},
	computed: {
		...mapState([
			'runnitState',
		]),
	},
	methods: {
		...mapActions([
			'updateSnackbar',
			'updateRunnitState',
		]),
		onClose () {
			this.updateRunnitState({
				promptGeneratorOpen: false,
			});
		},
		onUseEnhancedPromptClick (positive: string, negative: string) {
			this.$emit('on-use-enhanced-prompt', positive, negative);

			if (this.$vuetify.breakpoint.xsOnly) {
				this.onClose();
			}
		},
		async onClickGeneratePrompts () {
			this.generatedPrompts = [];
			this.errorGeneratingPrompts = false;
			this.loadingPrompts = true;
			try {
				const functionRef = functions
					.httpsCallable('promptMaker');
				const { success, generations, error } = (await functionRef({
					prompt: this.promptValue,
					numGenerations: this.numGenerations,
				})).data;

				if (success && generations && generations.length) {
					this.generatedPrompts = generations;
				} else if (!success) {
					this.errorGeneratingPrompts = true;
				}
			} catch (e) {
				console.error(e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error enhancing prompt, please try again',
					show: true,
				});
				this.loadingPrompts = false;
				this.errorGeneratingPrompts = true;
			} finally {
				this.loadingPrompts = false;
			}
		},
	},
	components: {
		GlassButton,
		RunnitMenuOrBottomSheet,
		ComplexBackgroundSettingMenu,
	},
});
