export const SELECTED_IMAGE_MODE: {
	TILED: 'TILED'
	INFO: 'INFO'
} = {
	TILED: 'TILED',
	INFO: 'INFO',
};

export type NodeRunResultSelectionMode =
	'SINGLE' |
	'MULTIPLE';

export const NODE_RUN_RESULT_SELECTION_MODE: Record<NodeRunResultSelectionMode, NodeRunResultSelectionMode> = {
	SINGLE: 'SINGLE',
	MULTIPLE: 'MULTIPLE',
};
interface SettingAction {
	id: string
	label: string
	icon: string
}

export const SETTING_ACTIONS: {
	COPY: SettingAction
} = {
	COPY: {
		id: 'COPY',
		label: 'Copy',
		icon: 'mdi-content-copy',
	},
};
export const RUNNITS_OWNER_SELECTION: {
	USER: 'USER'
	TEAM: 'TEAM'
} = {
	USER: 'USER',
	TEAM: 'TEAM',
};
export const RUNNIT_TOOL_SETTINGS_VALUES: {
	DELETE: string
	MOVE_UP: string
	MOVE_DOWN: string
	EDIT_INFO: string
} = {
	DELETE: 'DELETE',
	MOVE_UP: 'MOVE_UP',
	MOVE_DOWN: 'MOVE_DOWN',
	EDIT_INFO: 'EDIT_INFO',
};
export const RUNNIT_TOOL_SETTINGS_MENU_ITEMS: {
	id: number
	icon: string
	label: string
	value: string
}[] = [
	{
		id: 1,
		icon: 'mdi-pencil-outline',
		label: 'Edit Name, etc.',
		value: RUNNIT_TOOL_SETTINGS_VALUES.EDIT_INFO,
	},
	{
		id: 4,
		icon: 'mdi-delete-outline',
		label: 'Delete',
		value: RUNNIT_TOOL_SETTINGS_VALUES.DELETE,
	},
];
export const SELECTED_IMAGE_MODE_TILED_LABEL: string = 'Tiled (Columns) View';
export const SELECTED_IMAGE_MODE_INFO_LABEL: string = 'Info (Detailed) View';
export const SELECTED_IMAGE_MODE_MENU_ITEMS: {
	id: number
	icon: string
	label: string
	value: string
}[] = [
	{
		id: 1,
		icon: 'mdi-table',
		label: SELECTED_IMAGE_MODE_TILED_LABEL,
		value: SELECTED_IMAGE_MODE.TILED,
	},
	{
		id: 2,
		icon: 'mdi-table-of-contents',
		label: SELECTED_IMAGE_MODE_INFO_LABEL,
		value: SELECTED_IMAGE_MODE.INFO,
	},
];
export const SELECTED_COLUMNS_MAP = {
	[12]: '1',
	[6]: '2',
	[4]: '3',
	[3]: '4',
	[2]: '6',
	[1]: '12',
};
export const SELECTED_COLUMN_MENU_ITEMS: {
	id: number
	icon: string
	iconStyle?: Record<string, string>
	label: string
	value: number
}[] = [
	{
		id: 1,
		icon: 'mdi-format-columns',
		label: '1 Column',
		value: 12,
	},
	{
		id: 2,
		icon: 'mdi-format-columns',
		label: '2 Columns',
		value: 6,
	},
	{
		id: 3,
		icon: 'mdi-format-columns',
		label: '3 Columns',
		value: 4,
	},
	{
		id: 4,
		icon: 'mdi-format-columns',
		label: '4 Columns',
		value: 3,
	},
	{
		id: 5,
		icon: 'mdi-format-columns',
		label: '6 Columns',
		value: 2,
	},
	{
		id: 6,
		icon: 'mdi-format-columns',
		label: '12 Columns',
		value: 1,
	},
];

export const RUNNIT_APEX_RUNNIT_NODE_DEF_ID: string = 'mzttaygaC3NXy6qOerIn';
export const FEATURED_RUNNIT_NODE_DEF_IDS: string[] = [
	RUNNIT_APEX_RUNNIT_NODE_DEF_ID
];

export const FREE_PLAN_LIMIT_CLUB_ADD_ON_NUM_RUNNITS_LIMIT: number = 1; // Free plan limit value
export const FREE_PLAN_LIMIT_CLUB_ADD_ON_NUM_NODES_LIMIT: number = 3; // Free plan limit value

const basicQualityCard = {
	title: 'Lightning',
	icon: 'mdi-lightning-bolt',
	description: 'Perfect for quick ideas and experimentation! Get a generated image in 5-10 minutes.',
	timeEstimate: '5-10 mins',
}
const ultraQualityCard = {
	title: 'Standard',
	icon: 'mdi-creation',
	description: 'Ideal for higher-quality results. Generate a more detailed and refined image in 30-45 minutes.',
	timeEstimate: '35-45 mins',
	comingSoon: true,
}

const characterTypeCard = {
	title: 'Character',
	icon: 'mdi-account',
	description: 'Bring your vision to life by creating a unique character, whether a realistic person or a stylized cartoon.',
}

const styleTypeCard = {
	title: 'Art Style',
	icon: 'mdi-brush',
	description: 'Recreate the magic of your favorite artists or explore new aesthetics. Generate images in any style you can imagine.',
}

const objectTypeCard = {
	title: 'Object',
	icon: 'mdi-cube',
	description: 'Need inspiration for your next project? Generate images of specific objects, from everyday items to fantastical creations.',
}

export const TRAINING_TYPE_CARDS: Record<string, any> = {
	'MmXNBoWAcBdwRNV2EDGq': characterTypeCard,
	'cJOrWSRNYwt6szmO8MF5': styleTypeCard,
	'eb0OsnA83NYtQO7lVuhH': objectTypeCard,
}

export const TRAINING_QUALITY_CARDS: Record<string, any> = {
	'aPmZFBQ4b0Tq6o0w2m0X': basicQualityCard,
	'YgT3v5tSt7FJbPmqsZ9o': ultraQualityCard,
}
