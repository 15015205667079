
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import ListItem from '@/components/base/ListItem.vue';
import { SessionBackgroundTask, SESSION_BACKGROUND_TASK_TYPE, SESSION_BACKGROUND_TASK_STATUS } from '@run-diffusion/shared';
import _keys from 'lodash/keys';
import _find from 'lodash/find';

export default Vue.extend({
	name: 'NotificationsBtn',
	props: {
		isListItem: { type: Boolean, default: false },
	},
	data () {
		return {
			newSessionBackgroundTasks: {},
			newSessionBackgroundTasksCount: 0,
		};
	},
	computed: {
		...mapState([
			'toolbar',
			'currSessionBackgroundTasks',
		]),
		badgeContentText () {
			return this.newSessionBackgroundTasksCount < 100 ? this.newSessionBackgroundTasksCount : '99+';
		},
		showBtn () {
			return !!(
				this.currSessionBackgroundTasks.length &&
				this.toolbar &&
				this.toolbar.session
			);
		},
	},
	watch: {
		currSessionBackgroundTasks: {
			immediate: true,
			handler (newVal: SessionBackgroundTask[], oldVal: SessionBackgroundTask[]) {
				if (!this.toolbar.notificationsOpen && newVal && newVal.length && newVal !== oldVal) {
					this.$nextTick(() => {
						// Put in next tick so that showBtn can be true before the alert wants to show
						(newVal || []).forEach((sessionBackgroundTask: SessionBackgroundTask) => {
							const oldSessionBackgroundTask: SessionBackgroundTask = _find(oldVal, ['id', sessionBackgroundTask.id]) || null;
							if (
								!oldVal ||
								!oldVal.length ||
								!oldSessionBackgroundTask ||
								(
									// Pop the notification count again when it's done
									sessionBackgroundTask.type === SESSION_BACKGROUND_TASK_TYPE.DOWNLOAD_MODEL &&
									sessionBackgroundTask.status !== SESSION_BACKGROUND_TASK_STATUS.IN_PROGRESS &&
									oldSessionBackgroundTask.status === SESSION_BACKGROUND_TASK_STATUS.IN_PROGRESS
								)
							) {
								this.newSessionBackgroundTasks = {
									...this.newSessionBackgroundTasks,
									[sessionBackgroundTask.id]: sessionBackgroundTask,
								};
							}
						});
						this.newSessionBackgroundTasksCount = _keys(this.newSessionBackgroundTasks).length;
					});
				}
			},
		},
	},
	methods: {
		...mapActions([
			'updateToolbar',
		]),
		resetNewSessionBackgroundTasks () {
			this.newSessionBackgroundTasks = {};
			this.newSessionBackgroundTasksCount = 0;
		},
		openNotifications () {
			this.updateToolbar({
				mobileMenuOpen: false,
				notificationsOpen: true,
			});
			this.resetNewSessionBackgroundTasks();
		},
	},
	components: {
		ListItem,
	},
});
