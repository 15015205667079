
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import {
	getRunnitsSelectedColumnsVal,
	getRunnitsSelectedImageModeVal,
	setRunnitsSelectedColumnsVal,
	setRunnitsSelectedImageModeVal
} from '@/utils';
import {
	SELECTED_COLUMNS_MAP,
	SELECTED_IMAGE_MODE,
	SELECTED_IMAGE_MODE_TILED_LABEL,
	SELECTED_IMAGE_MODE_INFO_LABEL,
	SELECTED_COLUMN_MENU_ITEMS,
	SELECTED_IMAGE_MODE_MENU_ITEMS,
	NODE_RUN_RESULT_SELECTION_MODE,
} from '@/views/Runnits/constants';
import BaseStyledMenu from '@/components/base/BaseStyledMenu.vue';
import { RunnitsImageSelectMixin } from '@/mixins/RunnitsImageSelectMixin';
import { RunnitBulkActionsMixin } from '@/mixins/RunnitBulkActionsMixin';

export default Vue.extend({
	name: 'RunnitPageSettings',
	props: {
		iconOnly: { type: Boolean, default: false },
		iconColor: { type: String, default: 'rgba(255, 255, 255, 0.7)' },
		hideImageMode: { type: Boolean, default: false },
		hideSelectedColumns: { type: Boolean, default: false },
		hideSelectionMode: { type: Boolean, default: false },
	},
	mixins: [
		RunnitsImageSelectMixin,
		RunnitBulkActionsMixin,
	],
	data () {
		return {
			SELECTED_IMAGE_MODE,
			SELECTED_IMAGE_MODE_TILED_LABEL,
			SELECTED_IMAGE_MODE_INFO_LABEL,
			NODE_RUN_RESULT_SELECTION_MODE,
			selectedImageModeMenuItems: SELECTED_IMAGE_MODE_MENU_ITEMS,

			selectColumnsMap: SELECTED_COLUMNS_MAP,
			selectedColumnsMenuItems: SELECTED_COLUMN_MENU_ITEMS,
			selectedButtonStyles: {
				position: 'relative',
				borderRadius: '8px',
				background: `
					linear-gradient(var(--grey-875), var(--grey-875)) padding-box,
					linear-gradient(202deg, var(--primary-blue) 30%, var(--primary-purple), var(--primary-pink)) border-box
				`,
				border: '2px solid transparent',
			},
		};
	},
	computed: {
		...mapState([
			'runnitState',
			'user',
		]),
	},
	mounted () {
		const localSelectedImageMode: string = getRunnitsSelectedImageModeVal();
		const localSelectedColumns: number = getRunnitsSelectedColumnsVal();

		if (localSelectedImageMode || localSelectedColumns) {
			this.updateRunnitState({
				...(localSelectedImageMode && {
					selectedImageMode: localSelectedImageMode,
				}),
				...(localSelectedColumns && {
					selectedColumns: localSelectedColumns,
				}),
			});
		}
	},
	methods: {
		...mapActions([
			'updateRunnitState',
			'updateSnackbar',
		]),
		onSelectImageMode (imageMode: string) {
			this.updateRunnitState({
				selectedImageMode: imageMode,
			});
			setRunnitsSelectedImageModeVal(imageMode);
		},
		onSelectColumns (selectedColumns: number) {
			this.updateRunnitState({
				selectedColumns: selectedColumns,
			});
			setRunnitsSelectedColumnsVal(selectedColumns);
		},
		toggleImageGalleryDrawer () {
			this.updateRunnitState({
				imageGalleryDrawerOpen: !this.runnitState.imageGalleryDrawerOpen,
			});
		},
	},
	components: {
		BaseStyledMenu,
	},
});
