
import Vue from 'vue';
import ResourceTile from '@/views/Resources/ResourceTile.vue';
import { APP_OFFER_RESOURCE_TYPE } from '@run-diffusion/shared';
import { mapState } from 'vuex';

export default Vue.extend({
	name: 'Resources',
	props: {
		usingInsideDialog: { type: Boolean, default: false },
	},
	data () {
		return {
			resourceListTypes: {
				youtubeVideos: [],
				links: [],
			},
		};
	},
	mounted () {
		if (this.usingInsideDialog) {
			document.addEventListener('keydown', this.onKeydown);
		}
	},
	watch: {
		'$store.state.toolbar.resourcesDialog.open': {
			immediate: true,
			handler (newVal, oldVal) {
				if (newVal && newVal !== oldVal) {
					if (this._get(this.toolbar, 'resourcesDialog.appOffer')) {
						this.setResources(this._get(this.toolbar, 'resourcesDialog.appOffer'));
					}
				}
			}
		},
		'$store.state.toolbar.session.apps': {
			immediate: true,
			handler (newVal, oldVal) {
				if (newVal && newVal !== oldVal) {
					if (this._get(this.toolbar, 'session.apps[0]')) {
						this.setResources(this.appOffersMap[this._get(this.toolbar, 'session.apps[0]')]);
					}
				}
			}
		},
	},
	computed: {
		...mapState([
			'toolbar',
			'appOffersMap',
		]),
	},
	methods: {
		onClose () {
			this.$emit('on-close');
		},
		onKeydown (event) {
			if (event.key === 'Escape' || event.keyCode === 27) { // 'Escape' is the key value for the Esc key. keyCode 27 is for older browsers.
				this.onClose();
			}
		},
		setResources (appOffer) {
			this.resourceListTypes.youtubeVideos = appOffer.resources.filter((resource) => resource.type === APP_OFFER_RESOURCE_TYPE.YOUTUBE);
			this.resourceListTypes.links = appOffer.resources.filter((resource) => resource.type === APP_OFFER_RESOURCE_TYPE.LINK);
		},
	},
	components: {
		ResourceTile,
	},
});
