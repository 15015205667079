
import Vue from 'vue';
import {
	RUNNIT_NODE_DEF_TOOL_APP_TYPE,
} from '@run-diffusion/shared';
import RunnitModelStaticFields from "@/views/Runnits/RunnitSettings/static/RunnitModelStaticFields.vue";

export default Vue.extend({
	name: 'RunitStaticFieldsContainer',
	props: {
		node: { type: Object, required: true },
	},
	data () {
		return {
			RUNNIT_NODE_DEF_TOOL_APP_TYPE,
		};
	},
	components: {
		RunnitModelStaticFields

	},
});
