
import Vue from 'vue';
import EmptyState from '@/components/states/EmptyState.vue';
import LoadingSVG from '@/assets/LoadingSVG.vue';
import _slice from 'lodash/slice';
import { mapActions, mapState } from 'vuex';
import { SNACKBAR_STATUS } from '@/constants/constants';
import { db, functions } from '@/firebase';
import { Session, SESSION_STATE } from '@run-diffusion/shared';
import ConfirmDialog from '@/components/base/ConfirmDialog.vue';
import AdminSessionStats from '@/components/AdminStats/AdminSessionStats.vue';
import moment from 'moment-timezone';

export default Vue.extend({
	name: 'SessionsHistoryDialog',
	props: {
		value: { type: Boolean, default: false },
	},
	data () {
		return {
			historyTypeValue: 0,
			PRIVATE_HISTORY_TYPE: 0,
			TEAM_HISTORY_TYPE: 1,

			currPage: 0,
			pageSize: 50,
			currSessions: [],
			sessions: [],
			lastSessionDoc: null,
			hasMoreSessions: false,
			loadingInitialSessions: false,
			loadingMoreSessions: false,

			sessionsWithNoLogFilesMap: {},
			sessionLogFileUrlDatasToOpen: [],
			loadingLogFileForSessionId: null,
		};
	},
	computed: {
		...mapState([
			'appOffersMap',
			'hardwareOffersMap',
			'softwareOffersMap',
			'user',
			'team',
			'isAdminProxyingUser',
		]),
		canGoPrevPage () {
			return this.currPage > 0;
		},
		canGoNextPage () {
			return (
				(this.lastSessionDoc && this.hasMoreSessions) ||
				this.currPage < Math.floor(this.sessions.length / this.pageSize)
			);
		},
	},
	created () {
		this.loadInitialSessions(this.value, this.historyTypeValue);
	},
	watch: {
		value (newVal, oldVal) {
			if (newVal !== oldVal) {
				this.loadInitialSessions(newVal, this.historyTypeValue);
			}
		},
		historyTypeValue (newVal, oldVal) {
			if (newVal !== oldVal) {
				this.loadInitialSessions(this.value, newVal);
			}
		},
	},
	methods: {
		...mapActions([
			'updateSnackbar',
		]),
		onClose () {
			this.$emit('on-close');
		},
		getInitTimeStr (session: Session) {
			if (!session || !session.initAt || !session.bootAt) return null;
			return `${moment(session.bootAt.toMillis()).diff(session.initAt.toMillis(), 'seconds')} sec`;
		},
		getBootTimeStr (session: Session) {
			if (!session || !session.bootAt || !session.readyAt) return null;
			return `${moment(session.readyAt.toMillis()).diff(session.bootAt.toMillis(), 'seconds')} sec`;
		},
		getReadyMinsDisplay (session: Session) {
			if (session.state !== SESSION_STATE.STOPPED) {
				return 'Currently launched';
			}
			if (!session.readyMillis || session.readyMillis < 1000 * 60) {
				return 'Less than 1 min';
			}
			if (session.readyMillis < 1000 * 60 * 60) {
				return `${Math.floor(session.readyMillis / 1000 / 60)} min ${Math.round(session.readyMillis / 1000 % 60)} sec`;
			}
			return `${Math.floor(session.readyMillis / 1000 / 60 / 60)} hr ${Math.floor(session.readyMillis / 1000 / 60 % 60)} min ${Math.round(session.readyMillis / 1000 % 60)} sec`;
		},
		onOpenLogFileUrlClick (url: string) {
			if (url) {
				window.open(url, '_blank');
			}
		},
		async onDownloadSessionLogFile (sessionId: string) {
			this.loadingLogFileForSessionId = sessionId;
			const urlDatas: { fileName: string, url: string }[] = await this.getSessionLogFileUrlDatas(sessionId);
			if (urlDatas && urlDatas.length) {
				this.sessionLogFileUrlDatasToOpen = urlDatas;
			} else {
				this.sessionsWithNoLogFilesMap = {
					...this.sessionsWithNoLogFilesMap,
					[sessionId]: true,
				};
				this.updateSnackbar({
					status: SNACKBAR_STATUS.INFO,
					message: 'No logs found for that session',
					show: true,
				});
			}
			this.loadingLogFileForSessionId = null;
		},
		sliceCurrSessions () {
			const startIndex: number = (this.currPage * this.pageSize);
			const endIndex: number = ((this.currPage + 1) * this.pageSize);
			this.currSessions = _slice(
				this.sessions,
				startIndex,
				endIndex,
			);
		},
		onPrevPage () {
			this.currPage = this.currPage - 1;
			if (this.currPage < 0) this.currPage = 0;

			this.sliceCurrSessions();
		},
		async onNextPage () {
			this.currPage = this.currPage + 1;

			if (this.lastSessionDoc && this.hasMoreSessions) {
				const endIndex: number = (this.currPage * this.pageSize);
				if (endIndex >= this.sessions.length) {
					await this.loadMoreSessions();
				} else {
					this.sliceCurrSessions();
				}
			} else {
				this.sliceCurrSessions();
			}
		},
		async getSessionLogFileUrlDatas (sessionId: string) {
			const onError: Function = (e) => {
				console.error('Error loading session logs file: ', e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error loading session logs file',
					show: true,
				});
			};

			try {
				const functionRef = functions
					.httpsCallable('getSessionLogFileUrlDatas');
				const { urlDatas } = (await functionRef({
					sessionId,
				})).data;
				if (!urlDatas) {
					onError(new Error('getSessionLogFileUrlDatas returned undefined urlDatas'));
				}

				return urlDatas;
			} catch (e) {
				onError(e);
				return [];
			}
		},
		async loadSessions () {
			try {
				// index created = sessions: userId Ascending createdAt Descending __name__ Descending
				// index created = sessions: teamId Ascending createdAt Descending __name__ Descending
				let sessionsRef: any = db.collection('sessions')
					.orderBy('createdAt', 'desc')
					.limit(this.pageSize);
				if (this.historyTypeValue === this.PRIVATE_HISTORY_TYPE) {
					sessionsRef = sessionsRef
						.where('userId', '==', this.user.id);
				} else if (this.historyTypeValue === this.TEAM_HISTORY_TYPE) {
					sessionsRef = sessionsRef
						.where('teamId', '==', this.team.id);
				}
				if (this.lastSessionDoc) {
					sessionsRef = sessionsRef
						.startAfter(this.lastSessionDoc);
				}

				const sessionsSnapshot = await sessionsRef.get();
				const result: any[] = sessionsSnapshot.docs.map((doc: any) => {
					return {
						...doc.data(),
						get id () { return doc.id },
					};
				});
				const lastDoc: any = sessionsSnapshot.empty ? null : sessionsSnapshot.docs[sessionsSnapshot.docs.length - 1];

				return {
					success: true,
					result,
					lastDoc,
				};
			} catch (e) {
				console.error('Error loading sessions: ', e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error loading sessions',
					show: true,
				});

				return {
					success: false,
					result: [],
					lastDoc: null,
				};
			}
		},
		async loadInitialSessions (dialogOpen: boolean, historyTypeValue: number) {
			if (!this.loadingInitialSessions && !this.loadingMoreSessions && dialogOpen) {
				this.loadingInitialSessions = true;
				this.lastSessionDoc = null;
				this.hasMoreSessions = false;
				const { success, result, lastDoc } = await this.loadSessions(historyTypeValue);
				this.loadingInitialSessions = false;
				if (success) {
					this.currPage = 0;
					this.lastSessionDoc = lastDoc;
					this.hasMoreSessions = !!lastDoc;
					this.currSessions = result;
					this.sessions = this.currSessions;
				}
			}
		},
		async loadMoreSessions () {
			if (!this.loadingInitialSessions && !this.loadingMoreSessions) {
				this.loadingMoreSessions = true;
				const { success, result, lastDoc } = await this.loadSessions(this.historyTypeValue);
				this.loadingMoreSessions = false;
				if (success) {
					this.lastSessionDoc = lastDoc;
					this.hasMoreSessions = !!lastDoc;
					this.currSessions = result;
					this.sessions = this.sessions.concat(this.currSessions);
					this.$scrollTo('#sessions-history-dialog-scroll-anchor', 500, {
						container: '#sessions-history-dialog-scroll-container',
					});
				}
			}
		},
	},
	components: {
		AdminSessionStats,
		ConfirmDialog,
		LoadingSVG,
		EmptyState,
	},
});
