import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{attrs:{"value":_vm.open,"max-width":_vm.maxWidth,"overlay-opacity":"0.8","persistent":_vm.persistent},on:{"input":_vm.setOpen}},[_c('div',{staticClass:"runnit-dialog-outer"},[_c('div',{staticClass:"runnit-dialog-nav-section"},[_c('complex-background',{attrs:{"include-left-blobs":""}}),_vm._t("header",function(){return [_c('div',{staticClass:"runnit-dialog-nav-section-row"},[_c('div',{staticClass:"runnit-dialog-nav-section-title"},[_vm._t("header-icon",function(){return [_c(VIcon,{style:({
								'font-size': _vm.multiLineHeader ? '22px' : '30px',
								'margin': _vm.multiLineHeader ? '0 15px 0 0 ' : '0 10px 0 0',
								})},[_vm._v(_vm._s(_vm.icon))])]}),_c('div',{style:({
								'font-size': _vm.multiLineHeader ? '18px' : '24px',
							})},[_vm._t("header-title",function(){return [_vm._v(" "+_vm._s(_vm.title)+" ")]})],2),_vm._t("header-post-title")],2),(!_vm.noClose)?_c('div',{staticClass:"header-close"},[_c('base-button',{staticStyle:{"margin":"0 -5px 0 0"},attrs:{"icon":"","text":"","large":""},on:{"click":_vm.onCancel}},[_c(VIcon,{staticStyle:{"color":"var(--blue-grey-200)"}},[_vm._v("mdi-close")])],1)],1):_vm._e()]),_c('div',{staticClass:"runnit-dialog-nav-section-row"},[_c('div',{staticStyle:{"display":"flex","margin-bottom":"-8px","z-index":"1"}},[_vm._t("header-nav")],2),_c('div',{staticStyle:{"display":"flex","margin-bottom":"8px","z-index":"1"}},[_vm._t("header-actions")],2)])]})],2),_c('div',{staticClass:"runnit-dialog-body",style:({
				'height': _vm.bodyHeight,
				'overflow': 'hidden',
			})},[_c('div',{staticClass:"runnit-dialog-body-inner"},[_vm._t("default")],2),_c('div',{staticClass:"runnit-dialog-actions"},[_vm._t("actions")],2)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }