
import Vue from 'vue';
import {mapActions, mapGetters, mapState} from "vuex";
import {RUNNITS_OWNER_SELECTION} from "@/views/Runnits/constants";
import {setRunnitsTeamAccessLevel} from "@/utils";
import {
	RUNNITS_ACCESS_LEVEL,
	RunnitsAccessLevel
} from "@run-diffusion/shared";
import {ModelCRUDMixin} from "@/mixins/ModelCRUDMixin";
import EmptyState from "@/components/states/EmptyState.vue";
import ModelCard from "@/components/ModelCard.vue";
import {RunnitsCRUDMixin} from "@/mixins";
import ModelCreateOptionsDialog from "@/views/Runnits/pages/models/ModelCreateOptionsDialog.vue";

export default Vue.extend({
	name: 'RunnitModelsListPage',
	computed: {
		...mapState([
			'user',
			'team',
			'modelsState',
			'runnitState',
			'loadingModels',
		]),
		...mapGetters('modelUpload', [
			'getUploading',
			'allUploads'
		]),
        uploadingModelsForEnvironment() {
			return this.getUploading.filter(upload =>
				this.runnitState.runnitsOwnerSelection === RUNNITS_OWNER_SELECTION.TEAM
					? upload.model.teamId === this.team.id && upload.model.accessLevel === this.runnitState.runnitsAccessLevel
					: !upload.model.teamId
			);
		},
	},
	mixins: [RunnitsCRUDMixin, ModelCRUDMixin],
	components: {
		ModelCreateOptionsDialog,
		EmptyState,
		ModelCard
	},
	props: {},
	data () {
		return {
			RUNNITS_ACCESS_LEVEL,
			RUNNITS_OWNER_SELECTION,
			loadingRunnits: false,
			modelList: [],
			isModelCreateOptionsDialogOpen: false,
		};
	},
	methods: {
		...mapActions([
			'bindModels',
			'updateSnackbar',
			'updateModelState',
			'updateRunnitState',
		]),
		...mapActions('modelUpload', [
			'uploadModel',
		]),
		onModelsAccessLevelChange(accessLevel: RunnitsAccessLevel) {
			setRunnitsTeamAccessLevel(accessLevel);
			this.updateRunnitState({
				runnitsAccessLevel: accessLevel,
			});
		},
		onEditModelSettings(model) {
			if (model) {
				this.updateModelState({
					modelDraft: model,
					modelDialog: {
						...this.modelsState.modelDialog,
						open: true,
						mode: 'edit',
					},
				});
			}
		},
		openModelCreateOptions() {
			this.isModelCreateOptionsDialogOpen = true;
		}
	}
});
