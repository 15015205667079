import {
	RUNNIT_NODE_DEF_TOOL_TYPE,
	RUNNIT_NODE_DEF_PROVIDER,
} from '@run-diffusion/shared';

interface DROPDOWN_ITEM {
	id: string | null;
	name: string;
}

export const PROVIDER_ITEMS: DROPDOWN_ITEM[] = [
	{
		id: null,
		name: '---',
	},
	{
		id: RUNNIT_NODE_DEF_PROVIDER.RUNNIT_IMG,
		name: '"Runnit" branded image generation pipelines',
	},
	{
		id: RUNNIT_NODE_DEF_PROVIDER.RD,
		name: 'RunDiffusion',
	},
	{
		id: RUNNIT_NODE_DEF_PROVIDER.OCTOAI,
		name: 'octo.ai',
	},
	{
		id: RUNNIT_NODE_DEF_PROVIDER.RUNPOD,
		name: 'RunPod',
	},
	{
		id: RUNNIT_NODE_DEF_PROVIDER.IDEOGRAM,
		name: 'Ideogram',
	},
	{
		id: RUNNIT_NODE_DEF_PROVIDER.FALAI,
		name: 'FAL.ai',
	},
	{
		id: RUNNIT_NODE_DEF_PROVIDER.NICHETENSOR,
		name: 'NicheTensor',
	},
	{
		id: RUNNIT_NODE_DEF_PROVIDER.RUNWAY,
		name: 'RunwayML',
	},
	{
		id: RUNNIT_NODE_DEF_PROVIDER.LUMAAI,
		name: 'Lumaai',
	},
	{
		id: RUNNIT_NODE_DEF_PROVIDER.RUNWARE,
		name: 'Runware',
	},
];

export const NODE_TYPE_ITEMS: DROPDOWN_ITEM[] = [
	{
		id: null,
		name: '---',
	},
	{
		id: RUNNIT_NODE_DEF_TOOL_TYPE.CORE,
		name: 'Core',
	},
	{
		id: RUNNIT_NODE_DEF_TOOL_TYPE.TEAM,
		name: 'Team',
	},
];

export const ENDPOINT_ID_ITEMS: Record<string, DROPDOWN_ITEM[]> = {
	[RUNNIT_NODE_DEF_PROVIDER.RUNNIT_IMG]: [
		{
			id: null,
			name: '---',
		},
		{
			id: 'runnit-img-gen-1',
			name: 'Runnit Apex (Image Gen)',
		},
	],
	[RUNNIT_NODE_DEF_PROVIDER.RD]: [
		{
			id: null,
			name: '---',
		},
		{
			id: 'cmfy-workflow',
			name: '(COMFY) Default Comfy Workflow',
		},
	],
	[RUNNIT_NODE_DEF_PROVIDER.FALAI]: [
		{
			id: null,
			name: '---',
		},
		{
			id: 'creative-upscaler',
			name: 'Creative Upscaler',
		},
		{
			id: 'aura-sr-upscaler',
			name: 'AuraSR Upscaler',
		},
		{
			id: 'stable-diffusion-xl',
			name: 'Stable Diffusion XL',
		},
		{
			id: 'stable-diffusion-v35-large',
			name: 'Stable Diffusion v3.5 Large',
		},
		{
			id: 'remove-background',
			name: 'Remove Background',
		},
		{
			id: 'remove-background-birefnet',
			name: 'Remove Background Birefnet',
		},
		{
			id: 'flux-dev',
			name: 'Flux Dev',
		},
		{
			id: 'flux-dev-with-loras',
			name: 'Flux Dev with LoRAs',
		},
		{
			id: 'flux-dev-fill-with-loras',
			name: 'Flux Dev Fill with LoRAs',
		},
		{
			id: 'flux-pro',
			name: 'Flux Pro',
		},
		{
			id: 'flux-pro-1-1',
			name: 'Flux Pro 1.1 (Blueberry)',
		},
		{
			id: 'flux-pro-v1-1-redux',
			name: 'Flux Pro 1.1 Redux',
		},
		{
			id: 'flux-pro-v1-canny',
			name: 'Flux Pro Canny',
		},
		{
			id: 'flux-pro-v1-depth',
			name: 'Flux Pro Depth',
		},
		{
			id: 'flux-pro-v1-fill',
			name: 'Flux Pro Fill',
		},
		{
			id: 'flux-pro-v1-redux',
			name: 'Flux Pro Redux',
		},
		{
			id: 'flux-pro-v1-1-ultra',
			name: 'Flux Pro Ultra',
		},
		{
			id: 'flux-pro-v1-1-ultra-redux',
			name: 'Flux Pro Ultra Redux',
		},
		{
			id: 'flux-schnell',
			name: 'Flux Schnell',
		},
		{
			id: 'flux-lora-fast-training',
			name: 'Flux LoRa Fast Training',
		},
		{
			id: 'runway-gen-3-turbo',
			name: 'Runway Gen3 (turbo)',
		},
		{
			id: 'recraft-v3',
			name: 'Recraft v3',
		},
		{
			id: 'kling-standard-1-0',
			name: 'Kling 1.0 (standard)',
		},
		{
			id: 'kling-standard-1-6',
			name: 'Kling 1.6 (standard)',
		},
		{
			id: 'kling-pro-1-0',
			name: 'Kling 1.0 (pro)',
		},
		{
			id: 'kling-pro-1-5',
			name: 'Kling 1.5 (pro)',
		},
		{
			id: 'kling-pro-1-6',
			name: 'Kling 1.6 (pro)',
		},
		{
			id: 'minimax-video',
			name: 'MiniMax Video',
		},
	],
	[RUNNIT_NODE_DEF_PROVIDER.OCTOAI]: [
		{
			id: null,
			name: '---',
		},
		{
			id: 'stable-diffusion-xl',
			name: 'Stable Diffusion XL',
		},
	],
	[RUNNIT_NODE_DEF_PROVIDER.RUNWARE]: [
		{
			id: null,
			name: '---',
		},
		{
			id: 'generate',
			name: 'Generate',
		},
	],
	[RUNNIT_NODE_DEF_PROVIDER.RUNPOD]: [
		{
			id: null,
			name: '---',
		},
		{
			id: 'fooocus',
			name: 'Foooocus',
		},
	],
	[RUNNIT_NODE_DEF_PROVIDER.IDEOGRAM]: [
		{
			id: null,
			name: '---',
		},
		{
			id: 'generate',
			name: 'Generate',
		},
		{
			id: 'edit',
			name: 'Edit',
		},
	],
	[RUNNIT_NODE_DEF_PROVIDER.NICHETENSOR]: [
		{
			id: null,
			name: '---',
		},
		{
			id: 'dalle',
			name: 'DallE',
		},
	],
	[RUNNIT_NODE_DEF_PROVIDER.RUNWAY]: [
		{
			id: null,
			name: '---',
		},
		{
			id: 'image_to_video',
			name: 'Image To Video',
		},
	],
	[RUNNIT_NODE_DEF_PROVIDER.LUMAAI]: [
		{
			id: null,
			name: '---',
		},
		{
			id: 'video_generation',
			name: 'Video Generation',
		},
	],
};
