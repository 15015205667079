
import Vue from 'vue';
import { mapActions, mapState, mapGetters } from 'vuex';
import { db } from '@/firebase';
import _isNil from 'lodash/isNil';
import { MyBalanceMixin } from '@/mixins';
import {
	RunnitNode,
	getRunnitNodeRunPrice,
	RunnitNodeStaticFieldsKey,
	UserRunnitNodeDefInfo,
	RUNNIT_NODE_DEF_PRICING_TYPE,
	RUNNIT_NODE_STATIC_FIELDS_KEY,
	RUNNIT_NODE_STATIC_FIELDS_SOURCE,
	RUNNIT_NODE_DEF_TOOL_APP_TYPE, RUNNIT_NODE_DEF_TAG_TYPE, RunnitNodeDefTag,
} from '@run-diffusion/shared';
import BaseDrawer from '@/components/base/BaseDrawer.vue';
import RunnitTokens from '@/views/Runnits/RunnitTokens.vue';
import ComplexBackground from '@/components/designElements/ComplexBackground.vue';
import NumberWithIncrementer from '@/views/Runnits/RunnitSettings/NumberWithIncrementer.vue';
import AutoSaveRunnitNodeRunContainer from '@/views/Runnits/RunnitSettings/AutoSaveRunnitNodeRunContainer.vue';
import RunnitStaticFieldsContainer from '@/views/Runnits/RunnitSettings/static/RunnitStaticFieldsContainer.vue';
import RunnitNodeFieldSettingsInternalEditor from '@/views/Runnits/RunnitSettings/internalAdminOnly/RunnitNodeFieldSettingsInternalEditor.vue';
import { RunnitBulkActionsMixin } from '@/mixins/RunnitBulkActionsMixin';
import { StoreLoadingState } from '@/store';
import BaseButton from '@/components/base/BaseButton.vue';
import RunRunnitButton from './RunRunnitButton.vue';
import { TRAINING_QUALITY_CARDS } from "@/views/Runnits/constants";

export default Vue.extend({
	name: 'RunnitSettingsDrawer',
	mixins: [
		MyBalanceMixin,
		RunnitBulkActionsMixin,
	],
	props: {
		isAnyNodeSelected: { type: Boolean, default: false },
		selectedNode: { type: Object, default: null },
		actionsOnly: { type: Boolean, default: false },
		isSingleTool: { type: Boolean, default: false },
	},
	data () {
		return {
			RUNNIT_NODE_STATIC_FIELDS_KEY,
			RUNNIT_NODE_DEF_TOOL_APP_TYPE,

			incrementAutoSaveTrigger: 0,
			bottomOfScrollReached: false,

			// FORM
			formValid: true,

			fieldSettingsInternalEditorDialog: {
				open: false,
				selectedNode: null,
			},
		};
	},
	created () {
		// Add event listeners for keydown when the component is created
		document.addEventListener('keydown', this.handleKeyDown);
	},
	destroyed () {
		// Remove event listeners for keydown when the component is destroyed
		document.removeEventListener('keydown', this.handleKeyDown);
	},
	computed: {
		...mapState([
			'user',
			'team',
			'publicRunnitNodeDefTagsMap',
			'loadingDraftRunnitNodeRun',
			'draftRunnitNodeRun',
			'runnitState',
		]),
		...mapGetters([
			'isMobileNavOpen',
			'isSafari',
			'runnitNodesLimitMap',
		]),
		inputValues() {
			return this.runnitState.inputValues
		},
		staticInputValues() {
			return this.runnitState.staticInputValues
		},
		isOverPlanNodesLimit () {
			return !!(
				this.selectedNode &&
				!this.runnitNodesLimitMap[this.selectedNode.id]
			);
		},
		isToolUnpublished () {
			return this.selectedNode &&
				typeof this.selectedNode.nodeDef !== 'string' && // Nested object is fetched
				(!this.selectedNode.nodeDef.isPublished || !this.selectedNode.nodeDef.publishedAt) &&
				!this.user.isAdmin;
		},
		disableActions () {
			return !(!this.loadingDraftRunnitNodeRun && this.draftRunnitNodeRun);
		},
		determinedStaticFields () {
			if (!this.selectedNode) return {};
			return (
				this._get(this.selectedNode, 'staticFieldsSource') === RUNNIT_NODE_STATIC_FIELDS_SOURCE.NODE
					? this._get(this.selectedNode, 'staticFields')
					: this._get(this.selectedNode, 'nodeDef.staticFields')
			) || {};
		},
		numResultsStaticInputValue () {
			if (
				this.determinedStaticFields[RUNNIT_NODE_STATIC_FIELDS_KEY.numResults] &&
				this.staticInputValues[RUNNIT_NODE_STATIC_FIELDS_KEY.numResults] > 0
			) {
				return this.staticInputValues[RUNNIT_NODE_STATIC_FIELDS_KEY.numResults];
			}
			return 1;
		},
		totalRunCost () {
			if (
				this.selectedNode &&
				this.selectedNode.nodeDef &&
				typeof this.selectedNode.nodeDef !== 'string' && // Nested object is fetched
				this.selectedNode.nodeDef.pricingType === RUNNIT_NODE_DEF_PRICING_TYPE.COST &&
				this.selectedNode.nodeDef.costPerResult > 0
			) {
				return getRunnitNodeRunPrice(this.selectedNode.nodeDef.costPerResult, this.numResultsStaticInputValue);
			}
			return null; // null means can't get price yet, should disable the Runnit run button
		},
		estimatedTrainTime() {
			const tags: RunnitNodeDefTag[] = Object.keys(this.selectedNode.nodeDef.tags).map((tagId: string) => this.publicRunnitNodeDefTagsMap[tagId]);
			const qualityTag = tags.find((tag: RunnitNodeDefTag) => tag.type === RUNNIT_NODE_DEF_TAG_TYPE.TRAINING_QUALITY);
			if (qualityTag) {
				const qualityCard = TRAINING_QUALITY_CARDS[qualityTag.id];
				return qualityCard?.timeEstimate || '';
			} else {
				return '';
			}
		},
	},
	watch: {
		selectedNode: {
			immediate: true,
			handler (newVal: RunnitNode, oldVal: RunnitNode) {
				const newId: string = this._get(newVal, 'id') || null;
				const oldId: string = this._get(oldVal, 'id') || null;
				if (newId && newId !== oldId) {
					this.bindDraftRunnitNodeRun({ userId: this.user.id, nodeId: newId });
					this.updateUserRunnitNodeDefInfo(this.user.id, newVal.nodeDefId);
				}
			},
		},
		loadingDraftRunnitNodeRun: {
			immediate: true,
			handler (newVal: StoreLoadingState, oldVal: StoreLoadingState) {
				if (oldVal && !newVal && !this.draftRunnitNodeRun) {
					// create the initial draft with the default values
					this.incrementAutoSaveTrigger++;
				}
			}
		},
	},
	methods: {
		...mapActions([
			'updateSnackbar',
			'bindDraftRunnitNodeRun',
			'updateRunnitState',
			'setUpsellDialog',
			'updateModelState',
		]),
		_isNil,
		setSettingsDrawerOpen (value: boolean) {
			this.$emit('set-settings-drawer-open', value);
			if (value) {
				this.setBulkActionMenuOpen(false);
			}
		},
		onInputValuesInput (newval: any) {
			this.updateRunnitState({
				inputValues: {
					...this.inputValues,
					...newval,
				},
			});
		},
		onStaticInputValuesInputChange (newval: any) {
			this.updateRunnitState({
				staticInputValues: {
					...this.staticInputValues,
					...newval,
				},
			});
		},
		onStaticInputValuesChange (key: RunnitNodeStaticFieldsKey, val: any) {
			this.staticInputValues = {
				...this.staticInputValues,
				[key]: val,
			};

			this.$nextTick(() => {
				// on next tick, so that the state set above has a chance to render before triggering auto save
				this.incrementAutoSaveTrigger++;
			});
		},
		toggleAdminFieldsEditor (selectedNode: any) {
			this.fieldSettingsInternalEditorDialog = {
				...this.fieldSettingsInternalEditorDialog,
				open: !this.fieldSettingsInternalEditorDialog.open,
				selectedNode,
			};
		},
		handleScrollReachingBottom () {
			const scrollContainer = this.$refs.scrollContainer;

			this.bottomOfScrollReached = scrollContainer.scrollTop > 240;
		},
		setToolsLibraryDialogOpenState (open: boolean) {
			this.updateRunnitState({
				toolsLibraryDialogOpen: open,
			});
		},
		async updateUserRunnitNodeDefInfo (userId: string, nodeDefId: string) {
			try {
				const userRunnitNodeDefInfoRef = db
					.doc(`users/${userId}/runnitNodeDefInfos/${nodeDefId}`);
				const userRunnitNodeDefInfo: UserRunnitNodeDefInfo = (await userRunnitNodeDefInfoRef.get()).data() as UserRunnitNodeDefInfo;
				await userRunnitNodeDefInfoRef.set({
					...userRunnitNodeDefInfo,
					lastOpenAt: new Date(),
					nodeDefId,
					nodeDef: db.doc(`runnitNodeDefs/${nodeDefId}`)
				});
			} catch (e) {
				console.error(e);
			}
		},
		removeTool () {
			this.$emit('on-remove-tool', this.selectedNode);
		}
	},
	components: {
		BaseDrawer,
		RunnitTokens,
		ComplexBackground,
		NumberWithIncrementer,
		AutoSaveRunnitNodeRunContainer,
		RunnitStaticFieldsContainer,
		RunnitNodeFieldSettingsInternalEditor,
		BaseButton,
		RunRunnitButton,
	},
});
