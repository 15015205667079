
import Vue from 'vue';
import { db } from '@/firebase';
import {
	Avatar,
	Runnit,
	RunnitNodeRunResult,
	AVATAR_USE_CASE,
	IMAGE_UPLOAD_MODE,
	RUNNIT_TYPE,
	RUNNITS_ACCESS_LEVEL
} from '@run-diffusion/shared';
import _trim from 'lodash/trim';
import { mapActions, mapState } from 'vuex';
import { SNACKBAR_STATUS } from '@/constants/constants';
import GreyButton from '@/components/base/GreyButton.vue';
import ComplexBackground from '@/components/designElements/ComplexBackground.vue';
import HeroImageGallery from '@/views/Runnits/base/HeroImageGallery.vue';
import DoubleConfirmDangerDialog from '@/components/base/DoubleConfirmDangerDialog.vue';
import { ROUTER } from '@/router/constants';
import ActionsIsland from '@/components/ActionsIsland.vue';
import { IMAGE_GALLERY_DIALOG_NAV } from '@/components/ImageGallery/utils';
import ImageGalleryDialog from '@/components/ImageGallery/ImageGalleryDialog.vue';
import { NODE_RUN_RESULT_SELECTION_MODE, RUNNITS_OWNER_SELECTION } from './constants';
import { RunnitState } from '@/store';
import ImageUpload from '@/views/Runnits/RunnitSettings/ImageInput/ImageUpload.vue';
import RunnitImage from '@/views/Runnits/RunnitImage.vue';
import { RunnitsImageSelectMixin } from '@/mixins/RunnitsImageSelectMixin';
import { RunnitsCRUDMixin, TeamUserMixin } from '@/mixins';
import DialogOrBottomSheet from '../../components/base/DialogOrBottomSheet.vue';

export default Vue.extend({
	name: 'ConfigureRunnit',
	mixins: [
		RunnitsCRUDMixin,
		RunnitsImageSelectMixin,
		TeamUserMixin,
	],
	data () {
		return {
			RUNNITS_ACCESS_LEVEL,
			IMAGE_GALLERY_DIALOG_NAV,
			RUNNITS_OWNER_SELECTION,
			SELECTION_MODE_TRIGGER_ID: 'configure-runnit',
			IMAGE_UPLOAD_MODE,
			AVATAR_USE_CASE,

			// Form
			title: null,
			description: null,
			accessLevel: null,
			avatarId: null,
			avatar: null,

			formValid: false,
			rules: {
				required: [
					v => !!v || 'Required field',
				],
			},

			savingRunnitSettings: false,

			deleteRunnitDialog: {
				open: false,
				deleting: false,
			},

			imageGalleryNavTab: null,
			isUploading: false,
		};
	},
	watch: {
		runnitState: {
			immediate: true,
			async handler (newVal: RunnitState, oldVal: RunnitState) {
				if (newVal.configureRunnitOpen && newVal !== oldVal) {
					if (
						newVal.selectionStateHistory[this.SELECTION_MODE_TRIGGER_ID] &&
						newVal.selectionStateHistory[this.SELECTION_MODE_TRIGGER_ID].selectedNodeRunResults &&
						newVal.selectionStateHistory[this.SELECTION_MODE_TRIGGER_ID].selectedNodeRunResults.length
					) {
						this.handleLibrarySelection(newVal.selectionStateHistory[this.SELECTION_MODE_TRIGGER_ID].selectedNodeRunResults[0]);
					}
				}
			}
		},
		'runnitState.runnit': {
			immediate: true,
			async handler (newVal: Runnit, oldVal: Runnit) {
				if (newVal && newVal !== oldVal) {
					this.title = this._get(newVal, 'title') || 'Untitled Runnit';
					this.description = this._get(newVal, 'description') || null;
					this.avatarId = this._get(newVal, 'avatarId') || null;
					this.avatar = this._get(newVal, 'avatar') || null;
					this.accessLevel = (this._get(newVal, 'teamId') && this._get(newVal, 'accessLevel')) || this.runnitState.runnitsAccessLevel || RUNNITS_ACCESS_LEVEL.SHARED;
				}
			},
		},
		'runnitState.runnitDraft': {
			immediate: true,
			async handler (newVal: Runnit) {
				if (newVal) {
					this.title = this._get(newVal, 'title') || 'Untitled Runnit';
					this.description = this._get(newVal, 'description') || null;
					this.avatarId = this._get(newVal, 'avatarId') || null;
					this.avatar = this._get(newVal, 'avatar') || null;
					this.accessLevel = (this._get(newVal, 'teamId') && this._get(newVal, 'accessLevel')) || this.runnitState.runnitsAccessLevel || RUNNITS_ACCESS_LEVEL.SHARED;
				}
			}
		},
		'runnitState.configureRunnitOpen': {
			immediate: true,
			async handler (newVal: boolean, oldVal: boolean) {
				if (newVal && !oldVal) {
					this.resetForm();
				}
			},
		},
	},
	computed: {
		...mapState([
			'runnitState',
			'user',
			'runnits',
		]),
		createNewMode () {
			return !!this.runnitState.runnitDraft;
		},
		canEdit () {
			if (!this.runnitState.runnit) return false;
			return !!(
				this.createNewMode ||
				this.runnitState.runnit.userId === this.user.id || // you created it
				this.isTeamAdmin // team admin
			);
		},
	},
	methods: {
		...mapActions([
			'updateSnackbar',
			'updateRunnitState',
		]),
		setDialog (isOpen) {
			const configureRunnitOpen: boolean = isOpen || false;
			if (this.createNewMode) {
				this.updateRunnitState({
					configureRunnitOpen,
					runnitDraft: null,
					runnitDraftNodeRef: null,
				});
			} else {
				this.updateRunnitState({
					configureRunnitOpen,
				});
			}
		},
		resetForm () {
			if (this.createNewMode) {
				this.title = this._get(this.runnitState.runnitDraft, `title`) || null;
				this.description = this._get(this.runnitState.runnitDraft, `description`) || null;
				this.avatarId = this._get(this.runnitState.runnitDraft, 'avatarId') || null;
				this.avatar = this._get(this.runnitState.runnitDraft, 'avatar') || null;
				this.accessLevel = (this._get(this.runnitState.runnitDraft, 'teamId') && this._get(this.runnitState.runnitDraft, 'accessLevel')) || RUNNITS_ACCESS_LEVEL.PRIVATE;
				return;
			}
			this.title = this._get(this.runnitState.runnit, `title`) || null;
			this.description = this._get(this.runnitState.runnit, `description`) || null;
			this.avatarId = this._get(this.runnitState.runnit, 'avatarId') || null;
			this.avatar = this._get(this.runnitState.runnit, 'avatar') || null;
			this.accessLevel = (this._get(this.runnitState.runnit, 'teamId') && this._get(this.runnitState.runnit, 'accessLevel')) || RUNNITS_ACCESS_LEVEL.PRIVATE;
		},
		goToSubscriptionsRoute () {
			this.routerPush(this.$route, this.$router, { name: ROUTER.RUNNITS_SUBSCRIPTIONS });
			this.setDialog(false);
		},
		async onCreateNewRunnit () {
			try {
				this.savingRunnitSettings = true;

				const newRunnit: Runnit = {
					...this.runnitState.runnitDraft,
					title: this.title,
					description: this.description,
					avatarId: this.avatarId,
					avatar: this.avatar,
					accessLevel: this.accessLevel,
				};
				await this.createNewRunnit(newRunnit, this.runnitState.runnitDraftNodeRef, RUNNIT_TYPE.WORKFLOW);
			} catch (e) {
				console.error(e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error! Problem creating the Runnit, please reach out to report issues by clicking the support button in our top toolbar',
					show: true,
				});
			} finally {
				this.savingRunnitSettings = false;
				this.setDialog(false);
			}
		},
		async onSaveRunnitSettings () {
			if (!this.canEdit) {
				await this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: `Error: Insufficient permission to save Runnit`,
					show: true,
				});
				this.setDialog(false);
				return
			}
			try {
				this.savingRunnitSettings = true;
				const runnitRef = db.doc(`runnits/${this.runnitState.runnit.id}`);
				await runnitRef
					.update({
						title: _trim(this.title) || null,
						description: _trim(this.description) || null,
						accessLevel: this.accessLevel,
						...(this.avatarId ? {
							avatarId: this.avatarId,
							avatar: db.doc(`avatars/${this.avatarId}`)
						} : {
							avatarId: null,
							avatar: null,
						}),
					});
				this.updateSnackbar({
					status: SNACKBAR_STATUS.SUCCESS,
					message: `Success! Runnit Settings saved`,
					show: true,
				});
			} catch (e) {
				console.error(e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error! Problem saving the Runnit Settings, please reach out to report issues by clicking the support button in our top toolbar',
					show: true,
				});
			} finally {
				this.savingRunnitSettings = false;
				this.setDialog(false);
			}
		},
		onDeleteCancelReset () {
			this.deleteRunnitDialog = {
				open: false,
				deleting: false,
			};
		},
		async handleDeleteRunnitConfirm (closeAndResetCallback: Function) {
			if (!this.canEdit) {
				await this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: `Error: Insufficient permission to delete Runnit`,
					show: true,
				});
				if (closeAndResetCallback) {
					closeAndResetCallback();
				}
				this.onDeleteCancelReset();
				this.setDialog(false);
				return
			}
			try {
				this.deleteRunnitDialog = {
					...this.deleteRunnitDialog,
					deleting: true,
				};
				const runnitRef = db.doc(`runnits/${this.runnitState.runnit.id}`);
				await runnitRef
					.update({
						deletedAt: new Date(),
					});
				await this.updateSnackbar({
					status: SNACKBAR_STATUS.SUCCESS,
					message: `Success! Runnit deleted`,
					show: true,
				});
				await this.routerPush(this.$route, this.$router, { name: ROUTER.RUNNITS_HOME });
			} catch (e) {
				console.error(e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: `Error! Problem deleting the Runnit, please reach out to report issues by clicking the support button in our top toolbar`,
					show: true,
				});
			} finally {
				if (closeAndResetCallback) {
					closeAndResetCallback();
				}
				this.onDeleteCancelReset();
				this.setDialog(false);
			}
		},
		handleImageUpload (avatar: Avatar) {
			this.avatarId = avatar.id;
			this.avatar = avatar;
		},
		openImageGallery (tab) {
			this.imageGalleryNavTab = tab;
			this.updateRunnitState({
				imageGalleryDialogOpen: true,
			});
			this.enterSelectionMode(NODE_RUN_RESULT_SELECTION_MODE.SINGLE, this.SELECTION_MODE_TRIGGER_ID, null, ['IMG']);
		},
		clearSelectedImage () {
			this.updateRunnitState({
				fieldAwaitingImageGallerySelection: null,
			});
			this.exitSelectionMode(this.SELECTION_MODE_TRIGGER_ID);
		},
		handleImageSelected (avatar: Avatar) { // note: this is an avatarRef
			this.avatarId = avatar.id;
			this.avatar = avatar;
		},
		async handleLibrarySelection (selection: RunnitNodeRunResult) {
			// TODO implement this.
			// console.log('handleLibrarySelection', {selection});
			// Add the RunnitNodeRunResult image to the avatars bucket
			// Assign the newly created avatar to the runnit settings (this.settings = avatar)
			// this.clearSelectedImage(); // TODO I'm a little concerned with how the image selection will effect the
			//		runnit pipeline forms. We might need to have the image gallery emit the value so we don't change the runnitState.
		},
	},
	components: {
		ActionsIsland,
		DoubleConfirmDangerDialog,
		HeroImageGallery,
		GreyButton,
		ComplexBackground,
		ImageGalleryDialog,
		ImageUpload,
		RunnitImage,
		DialogOrBottomSheet,
	},
});
