
import Vue from 'vue';
import { SURVEY_KEY } from '@run-diffusion/shared';

export default Vue.extend({
	name: 'SurveyFieldsDisplay',
	props: {
		survey: { type: Object, required: true },
	},
	computed: {
		fieldKeys () {
			switch (this.survey.key) {
				case SURVEY_KEY.NEW_TEAM_1:
					return [
						'companyName',
						'whatWorkingOn',
						'whoMainUsers',
						'supportNeeded',
					];
				case SURVEY_KEY.NEW_USER_1:
					return [
						'familiarRating',
						'proficientRating',
						'trafficSource',
						'trafficSourceDetails',
					];
				case SURVEY_KEY.USER_USAGE_1:
					return [
						'alternativeSolution',
						'biggestPain',
						'title',
					];
				default:
					return [];
			}
		},
	},
});
