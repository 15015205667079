import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"runnit-nav",style:({
			background: _vm.$vuetify.breakpoint.mdAndUp ? 'var(--dark-purple-900)' : 'var(--dark-purple-gradient-120)',
		})},[_c('div',{staticClass:"runnit-nav-background"}),_c('div',{staticClass:"runnit-nav-content"},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('complex-background-side-nav'):_vm._e(),_c('div',{staticClass:"runnit-nav-upper"},[_c('div',{staticClass:"runnit-nav-logo",class:{
						'mobile': _vm.$vuetify.breakpoint.xsOnly
					}},[_c('div',{staticClass:"temp-runnits-logo"},[_c('runnits-vertical-logo-s-v-g',{attrs:{"height":80,"fill":"white"}})],1),(_vm.$vuetify.breakpoint.xsOnly)?_c(VBtn,{staticStyle:{"position":"absolute","right":"-10px","top":"-2px","z-index":"100"},attrs:{"icon":""},on:{"click":_vm.closeSideNav}},[_c(VIcon,{staticStyle:{"color":"rgba(255, 255, 255, 0.5)"}},[_vm._v("mdi-close")])],1):_vm._e()],1),_c('div',{staticClass:"runnit-nav-section-title",staticStyle:{"margin-right":"10px"}},[_vm._v(" Runnits "),((
							_vm.team &&
							_vm.runnitState.runnitsOwnerSelection === _vm.RUNNITS_OWNER_SELECTION.TEAM
						))?_c('div',{staticClass:"runnit-nav-section-title-sub"},[_vm._v(" - (Team: "),_c('b',[_vm._v(_vm._s(_vm._f("truncate")(_vm.team.name,{ length: 17 })))]),_vm._v(") ")]):_vm._e()]),_c('div',{class:{
						'runnit-nav-item': !_vm.isHomeRoute,
						'runnit-nav-item-selected': _vm.isHomeRoute,
					},on:{"click":function($event){return _vm.goTo(_vm.ROUTER.RUNNITS_HOME)}}},[_c(VIcon,{staticClass:"runnit-nav-item-icon"},[_vm._v(" mdi-home-variant-outline ")]),_c('div',{staticClass:"runnit-nav-item-label"},[_vm._v(" Home ")])],1),_c('div',{class:{
						'runnit-nav-item': !_vm.isPrivateRoute,
						'runnit-nav-item-selected': _vm.isPrivateRoute,
					},on:{"click":_vm.onPrivateClick}},[_c(VIcon,{staticClass:"runnit-nav-item-icon"},[_vm._v(" mdi-account-circle-outline ")]),_c('div',{staticClass:"runnit-nav-item-label"},[_vm._v(" "+_vm._s(_vm.runnitState.runnitsOwnerSelection === _vm.RUNNITS_OWNER_SELECTION.TEAM ? 'Private' : 'Personal')+" ")])],1),(_vm.runnitState.runnitsOwnerSelection === _vm.RUNNITS_OWNER_SELECTION.TEAM)?_c('div',{class:{
						'runnit-nav-item': !_vm.isSharedRoute,
						'runnit-nav-item-selected': _vm.isSharedRoute,
					},on:{"click":_vm.onSharedClick}},[_c(VIcon,{staticClass:"runnit-nav-item-icon"},[_vm._v(" mdi-account-multiple-outline ")]),_c('div',{staticClass:"runnit-nav-item-label"},[_vm._v(" Shared ")])],1):_vm._e(),_c('div',{staticClass:"runnit-nav-section-title"},[_vm._v(" Tools ")]),_c('div',{staticClass:"runnit-nav-item",on:{"click":_vm.onFluxProClick}},[_c('img',{staticClass:"runnit-nav-item-img",attrs:{"src":"https://firebasestorage.googleapis.com/v0/b/image-ai-cb35d.appspot.com/o/assets%2Frd-webapp%2Frunnit-node-hero-images%2Fflux_1_1_pro_hero.png?alt=media&token=881fef09-e230-4221-be57-8877c3e7e65d","alt":"Flux 1.1 Pro Icon Image"}}),_c('div',{staticClass:"runnit-nav-item-label"},[_vm._v(" Flux 1.1 [Pro] ")])]),_c('div',{staticClass:"runnit-nav-item",on:{"click":_vm.onCreativeUpscalerClick}},[_c('img',{staticClass:"runnit-nav-item-img",attrs:{"src":"https://storage.googleapis.com/falserverless/gallery/creative-upscaler.webp?v=3","alt":"Creative Upscaler Icon Image"}}),_c('div',{staticClass:"runnit-nav-item-label"},[_vm._v(" Creative Upscaler ")])]),((_vm.user && _vm.user.isAdmin) || (_vm.user && _vm.user.features && _vm.user.features.runnitModels))?_c('div',{class:{
						'runnit-nav-item': !_vm.isModelsRoute,
						'runnit-nav-item-selected': _vm.isModelsRoute,
					},staticStyle:{"display":"flex","align-items":"center"},on:{"click":function($event){return _vm.goTo(_vm.ROUTER.RUNNITS_MODELS_LIST)}}},[_c(VIcon,{staticClass:"runnit-nav-item-icon"},[_vm._v(" mdi-vector-circle ")]),_c('div',{staticClass:"runnit-nav-item-label"},[_vm._v(" Models ")]),(_vm.getUploading.length)?_c('loading-state',{attrs:{"color":"white","size":20,"container-styles":"","hide-display-text":"","progress":Math.max(..._vm.getUploading.map(uploadingModel => uploadingModel.progress), 0)}}):_vm._e()],1):_vm._e(),_c('div',{class:{
						'runnit-nav-item': !_vm.isAllToolsRoute,
						'runnit-nav-item-selected': _vm.isAllToolsRoute,
					},on:{"click":function($event){return _vm.goTo(_vm.ROUTER.RUNNITS_ALL_TOOLS)}}},[_c(VIcon,{staticClass:"runnit-nav-item-icon"},[_vm._v(" mdi-view-grid-outline ")]),_c('div',{staticClass:"runnit-nav-item-label"},[_vm._v(" View All ")])],1),_c('div',{staticClass:"runnit-nav-item",on:{"click":_vm.openRunDiffusionInNewTab}},[_c(VIcon,{staticClass:"runnit-nav-item-icon"},[_vm._v(" mdi-open-in-new ")]),_c('div',{staticClass:"runnit-nav-item-label"},[_vm._v(" RunDiffusion ")])],1)]),_c('div',{staticClass:"runnit-nav-lower"},[(false)?_c('div',{staticClass:"runnit-nav-item",staticStyle:{"width":"100%","margin-left":"0","margin-bottom":"10px"},on:{"click":function($event){return _vm.goTo(_vm.ROUTER.RUNNITS_SUBSCRIPTIONS)}}},[_c(VIcon,{staticClass:"runnit-nav-item-icon"},[_vm._v(" mdi-crown-circle-outline ")]),_c('div',{staticClass:"runnit-nav-item-label"},[(_vm.user && _vm.user.clubOffer && typeof _vm.user.clubOffer !== 'string' && !_vm.user.clubOffer.isFreemium)?_c('span',[_vm._v("Manage Subscription")]):_c('span',[_vm._v("Premium Plans")])])],1):_vm._e(),_c('div',{staticClass:"account-tokens-menu"},[_c('runnit-account-selection-dialog',{staticClass:"full-width",attrs:{"is-side-nav-design":""}}),_c('div',{staticClass:"account-tokens-menu-divider"}),_c('runnit-tokens',{attrs:{"background":"transparent","token-size":14}})],1)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }