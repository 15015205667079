
import Vue from 'vue';
// import InitSessions from '@/views/Sessions/InitSessions/InitSessions.vue';
import { mapActions, mapState } from 'vuex';
import { Announcement, ANNOUNCEMENT_TYPE} from '@run-diffusion/shared';
import CreatorsClubPromoTeaser from '@/components/PromoBanners/CreatorsClubPromoTeaser.vue';
// import ConfigureCustomSession from '@/components/ConfigureCustomSession/ConfigureCustomSession.vue';
import AppOffersList from '@/views/Sessions/AppOffersList/AppOffersList.vue';
import JoinOurDiscord from '@/components/Toolbar/JoinOurDiscord.vue';
import RunnitsPromoCard from '@/views/Runnits/RunnitsPromoCard.vue';

export default Vue.extend({
	name: 'Sessions',
	data () {
		return {
			selectedStorage: null,
			dismissedNoNewSessionsAnnouncements: false,
		};
	},
	computed: {
		...mapState([
			'upsellDialog',
			'user',
			'team',
			'announcements',
			'stableDiffusionModel',
			'appQueryParamsData',
		]),
		noNewSessionsAnnouncements () {
			return this.announcements.filter((announcement: Announcement) => (
				announcement.type === ANNOUNCEMENT_TYPE.NO_NEW_SESSIONS
			));
		},
		runnitsEnabled () {
			return !!(
				(
					this.user &&
					this.user.isAdmin
				) ||
				this._get(this.team, 'features.runnits') ||
				this._get(this.user, 'features.runnits')
			);
		},
	},
	mounted () {
		this.initCreatorsClubDialog();

		this.updateToolbar({
			insideRunnits: false,
		});
	},
	methods: {
		...mapActions([
			'updateToolbar',
			'updateAppQueryParamsData',
			'setUpsellDialog',
		]),
		initCreatorsClubDialog () {
			if (!this.upsellDialog.creatorsClub && this.user && this.appQueryParamsData && this.appQueryParamsData.creatorsClubSignUp) {
				// Pop Creator's Club promotional dialog
				this.setUpsellDialog({ creatorsClub: true });
				// Reset creatorsClubSignUp to false
				this.updateAppQueryParamsData({ creatorsClubSignUp: false });
			}
		},
	},
	components: {
		RunnitsPromoCard,
		JoinOurDiscord,
		AppOffersList,
		CreatorsClubPromoTeaser,
		// ConfigureCustomSession,
		// InitSessions,
	},
});
