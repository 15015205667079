import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperHeader } from 'vuetify/lib/components/VStepper';
import { VStepperStep } from 'vuetify/lib/components/VStepper';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex flex-column justify-center align-center pa-12",staticStyle:{"min-height":"calc(100vh - 64px)","overflow-y":"auto"}},[_c('div',{staticClass:"d-flex justify-center full-width pb-3 mb-6",class:{
			'flex-row': _vm.$vuetify.breakpoint.smAndUp,
			'flex-column': _vm.$vuetify.breakpoint.xsOnly,
		}},[_c('session-info-card',{staticClass:"pa-5",attrs:{"show-app":"","show-hardware":"","show-software":"","min-width":_vm.$vuetify.breakpoint.smAndUp ? '350px' : '200px',"loading":"","show-multi-sessions-btn":""}})],1),(_vm.hasRetriedInitAtLeastOnce)?_c('info-message',{staticClass:"mt-4",staticStyle:{"background-color":"var(--grey-875)"},attrs:{"title":"Sorry"}},[_vm._v(" It is taking longer than expected to start this session. Please be patient. ")]):[(_vm.showPreloadModelInstructions && _vm.preloadModelIsSessionPreloadModel)?[_c('preload-model-instructions',{staticClass:"mb-6",attrs:{"show-hide-btn":""},on:{"on-hide":function($event){_vm.showPreloadModelInstructions = false}}}),_c('div',{staticClass:"text-subtitle-1 text-center mt-8 mb-4",staticStyle:{"color":"var(--grey-500)","max-width":"720px"}},[_vm._v(" We're setting everything up for you right now, so this will take about "),_c('span',{staticClass:"gradient-text",staticStyle:{"font-weight":"bold"}},[_vm._v("90 seconds to 5 minutes")]),_vm._v(" depending on how big the model is that we are downloading for you. "),_c('b',[_vm._v("You are not getting charged for this startup time.")])])]:[(_vm.preloadModelIsSessionPreloadModel)?_c('preload-model-preview',{staticClass:"mb-7",staticStyle:{"max-width":"740px"},attrs:{"small":"","show-model-name":"","message-html":_vm.$vuetify.breakpoint.smAndUp ? 'This model will download in the background while your session boots!' : null},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('base-button',{staticClass:"ml-6",attrs:{"secondary":"","small":""},on:{"click":function($event){_vm.showPreloadModelInstructions = true}}},[_c(VIcon,{attrs:{"small":"","left":""}},[_vm._v("mdi-unfold-more-horizontal")]),_vm._v(" Learn More ")],1)]},proxy:true}],null,false,613676967)}):_vm._e(),_c('div',{staticClass:"text-subtitle-1 text-center",class:{
					'my-0': _vm.surveysNotComplete,
					'my-6': !_vm.surveysNotComplete,
				},staticStyle:{"color":"var(--grey-500)","max-width":"720px"}},[_vm._v(" We're setting everything up for you right now, so this will take about "),_c('span',{staticClass:"gradient-text",staticStyle:{"font-weight":"bold"}},[_vm._v("60 seconds to 3 minutes")]),_vm._v(" depending on selection. "),_c('b',[_vm._v("You are not getting charged for this startup time.")]),(_vm.$vuetify.breakpoint.xsOnly)?_c('span',[_c('br'),_c('br')]):_vm._e(),_vm._v(" While you wait, "),(_vm.surveysNotComplete)?_c('span',[_c('span',{staticClass:"gradient-text"},[_vm._v("fill out the survey")]),_vm._v(" or ")]):_c('span',[_vm._v("join our discord")]),_vm._v(" and give us feedback! ")]),_c(VExpandTransition,[(_vm.introSurveyNotComplete)?_c('new-user-survey',{staticClass:"mt-8",on:{"on-finish":function($event){_vm.justFinishedNewUserSurvey = true}}}):_vm._e()],1),_c(VExpandTransition,[((
						!_vm.introSurveyNotComplete &&
						_vm.userUsage1SurveyNotComplete
					))?_c('user-usage1-survey',{staticClass:"mt-8",on:{"on-finish":function($event){_vm.justFinishedUserUsage1Survey = true}}}):(!_vm.surveysNotComplete)?_c('join-our-discord',{staticClass:"mt-3 mb-5",attrs:{"invite-link-redirect-only":""}}):_vm._e()],1)]],(_vm.$vuetify.breakpoint.smAndUp)?_c(VStepper,{staticStyle:{"background":"none"},attrs:{"value":_vm.stepperVal,"flat":""}},[_c(VStepperHeader,[_c(VStepperStep,{attrs:{"complete":_vm.stepperVal > 1,"color":_vm.stepperVal === 1 ? '#616161' : 'primary',"step":"1"}},[_c(VListItem,{attrs:{"two-line":""}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Ready!")]),_c(VListItemSubtitle,[_vm._v("Purchase confirmed")])],1)],1)],1),_c(VStepperStep,{attrs:{"complete":_vm.stepperVal > 2,"color":_vm.stepperVal === 2 ? '#616161' : 'primary',"step":"2"}},[_c(VListItem,{attrs:{"two-line":""}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Set!")]),(_vm.stepperVal > 2)?_c(VListItemSubtitle,[_vm._v("Session initialized")]):_vm._e(),(_vm.stepperVal === 2)?_c(VListItemSubtitle,[_vm._v("Session is initializing...")]):_vm._e()],1)],1)],1),_c(VStepperStep,{attrs:{"complete":_vm.stepperVal > 3,"color":_vm.stepperVal === 3 ? '#616161' : 'primary',"step":"3"}},[_c(VListItem,{attrs:{"two-line":""}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Go!")]),(_vm.stepperVal > 3)?_c(VListItemSubtitle,[_vm._v("Stable Diffusion started")]):_vm._e(),(_vm.stepperVal === 3)?_c(VListItemSubtitle,[_vm._v("Session starting...")]):_vm._e()],1)],1)],1)],1)],1):_vm._e(),_c('div',{staticClass:"d-flex",style:({
			margin: _vm.surveysNotComplete
				? '40px 0 20px 0'
				: '60px 0 20px 0'
		})},[_c('confirm-dialog',{attrs:{"disabled":_vm.stoppingSession,"confirm-loading":_vm.stoppingSession,"title":"Cancel Session","body-text":"Are you sure you want to cancel this session?","cancel-text":"No, Go Back","confirm-text":"Yes, Cancel"},on:{"on-confirm":_vm.cancelSession},scopedSlots:_vm._u([{key:"activator",fn:function({ on: confirmDialogOn }){return [_c(VTooltip,{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on: tooltipOn, attrs }){return [_c('base-button',_vm._g(_vm._b({staticClass:"px-7",attrs:{"loading":_vm.stoppingSession,"disabled":_vm.stoppingSession || !_vm.isCancellable,"secondary":""}},'base-button',attrs,false),{ ...confirmDialogOn, ...tooltipOn }),[_vm._v(" Cancel Session ")])]}}],null,true)},[_c('div',{staticClass:"tooltip-max-width"},[_vm._v("Cancel this session")])])]}}])})],1),_c(VSpacer)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }