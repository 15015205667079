
import Vue from 'vue';
import { mapState } from 'vuex';
import {
	RUNNITS_ACCESS_LEVEL,
} from '@run-diffusion/shared';
import { RUNNITS_OWNER_SELECTION } from "@/views/Runnits/constants";
import _trim from "lodash/trim";
import { ModelCRUDMixin } from "@/mixins/ModelCRUDMixin";

export default Vue.extend({
	name: 'RunnitModelStaticFields',
	props: {
		node: { type: Object, required: true },
	},
	mixins: [
		ModelCRUDMixin,
	],
	async created () {
		if (!this.modelsState.modelDraft) {
			const modelDraft = await this.createModelDraft();
			await this.updateModelState({
				modelDraft,
			});
		}
	},
	destroyed () {
		this.updateModelState({
			modelDraft: null,
		});
	},
	data () {
		return {
			RUNNITS_ACCESS_LEVEL,
			RUNNITS_OWNER_SELECTION,
		};
	},
	computed: {
		...mapState([
			'runnitState',
			'modelsState',
		]),
		shouldRenderFields () {
			return !(this.loadingDraftRunnitNodeRun || this.loadingDraftRunnitNodeRun === null);
		},
		isNodeRunQueuing () {
			return !!(
				this.draftRunnitNodeRun &&
				this.node.id === this.draftRunnitNodeRun.nodeId &&
				this.runnitState.isQueuingDraftRunnitNodeRun
			);
		},
	},
	methods: {
		_trim

	},
});
