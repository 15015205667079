
import Vue from 'vue';
import {MODEL_BASE_NODE_DEF_MAP, MODEL_BASE_NODE_DEF_TYPE, RUNNIT_NODE_DEF_TOOL_TYPE} from '@run-diffusion/shared';
import RunnitImage from '@/views/Runnits/RunnitImage.vue';
import ConfirmDialog from "@/components/base/ConfirmDialog.vue";
import {functions} from "@/firebase";
import {mapActions} from "vuex";
import {SNACKBAR_STATUS} from "@/constants/constants";
import RunnitImageTimer from "@/views/Runnits/RunnitImageTimer.vue";
import moment from "moment-timezone";

export default Vue.extend({
	name: 'ModelCard',
	props: {
		model: {type: Object, default: () => ({})},
		disabled: {type: Boolean, default: false},
		selectIcon: {type: String, default: 'mdi-check-circle-outline'},
		selectText: {type: String, default: 'Select'},
		showEditBtn: {type: Boolean, default: false},
		showCreateToolBtn: {type: Boolean, default: false},
		createText: {type: String, default: 'Create Tool'},
		editText: {type: String, default: 'Edit'},
		disabledText: {type: String, default: null},
		disabledCtaText: {type: String, default: null},
		disabledCtaProps: {type: Object, default: () => ({})},
		imageUrl: {type: String, default: ''},
		displayOnly: {type: Boolean, default: false},
		horizontal: {type: Boolean, default: false},
		publishedAt: {type: Object, default: null},
		showNotPublished: {type: Boolean, default: false},
		small: {type: Boolean, default: false},
		toolType: {type: String, required: false},
		isFeatured: {type: Boolean, required: false},
		noClick: {type: Boolean, default: false}, // pass through to the runnit-image noClick
		loading: {type: Boolean, default: false},
		loadingProgress: {type: Number, default: 0},
	},
	data() {
		return {
			RUNNIT_NODE_DEF_TOOL_TYPE,
			MODEL_BASE_NODE_DEF_MAP,
			createToolConfirmDialogOpen: false,
			creatingTool: false,
			selectedBaseModel: MODEL_BASE_NODE_DEF_TYPE.FLUX_BASE,
			containerWidth: 256,
			containerHeight: 256,
			imageHeightWidth: 256,
		};
	},
	methods: {
		moment,
		...mapActions([
			'updateSnackbar',
		]),
		async createTool() {
			this.createToolConfirmDialogOpen = false;
			this.creatingTool = true;
			const functionRef = functions.httpsCallable('createNodeDefFromModel');

			try {
				await functionRef({modelId: this.model.id, modelBaseNodeDef: this.selectedBaseModel});
				this.updateSnackbar({
					status: SNACKBAR_STATUS.SUCCESS,
					message: 'Tool created successfully',
					show: true,
				});
			} catch (e) {
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error creating tool',
					show: true,
				});
			} finally {
				this.creatingTool = false;
			}
		},
	},
	computed: {
		vertical() {
			return !this.horizontal;
		},
		computedDescriptionCharLimit() {
			return this.small ? 77 : 70;
		},
		modelCardStyles() {
			let styles = {
				background: 'linear-gradient(180deg, var(--grey-825), var(--grey-835))',
			};

			if (this.toolType === RUNNIT_NODE_DEF_TOOL_TYPE.TEAM) {
				styles.background = 'var(--blue-grey-800)';
				styles['backgroundImage'] = 'radial-gradient(circle at -30% 0, var(--blue-grey-500), rgba(255, 255, 255, 0) 70%)';
			}

			return styles;
		},
		descriptionStyles() {
			const styles = {
				color: 'rgba(255, 255, 255, 0.6)',
			};

			if (this.toolType === RUNNIT_NODE_DEF_TOOL_TYPE.TEAM) {
				styles.color = 'var(--blue-grey-200)';
			}

			return styles;
		},
		createdAt() {
			const modelCreatedAt = this.model.createdAt;

			if (modelCreatedAt) {
				if (typeof modelCreatedAt.toMillis === 'function') {
					return modelCreatedAt.toMillis();
				} else {
					const timestamp = new Date(modelCreatedAt).getTime();
					if (!isNaN(timestamp)) {
						return timestamp;
					}
				}
			}

			return null;
		}
	},
	components: {
		RunnitImageTimer,
		ConfirmDialog,
		RunnitImage,
	},
});
