import Vue from 'vue';
import { db } from '@/firebase';
import { mapActions, mapState } from 'vuex';
import {
	Avatar,
	MODEL_TYPE,
	RUNNITS_ACCESS_LEVEL,
	MODEL_ARCHITECTURE,
	ModelCreateMethod,
} from '@run-diffusion/shared';
import { RUNNITS_OWNER_SELECTION } from '@/views/Runnits/constants';
import _sampleSize from 'lodash/sampleSize';

export const ModelCRUDMixin = Vue.extend({
	computed: {
		...mapState([
			'user',
			'team',
			'runnits',
			'runnitState',
			'runnitNodesMap',
			'modelsState',
		]),
	},
	methods: {
		...mapActions([
			'updateLoader',
			'updateSnackbar',
			'updateModelState',
		]),
		async createModelDraft () {
			const chosenRandomAvatar = await this.getRandomDefaultAvatar();
			const nowDate: Date = new Date();
			const name = 'Untitled Model';

			return {
				createdAt: nowDate,
				deletedAt: null,
				isDeleted: false,
				userId: this.user.id,
				...(this.runnitState.runnitsOwnerSelection === RUNNITS_OWNER_SELECTION.TEAM ? {
					teamId: this.team.id,
					accessLevel: this.runnitState.runnitsAccessLevel || RUNNITS_ACCESS_LEVEL.PRIVATE,
				} : {
					teamId: null,
					accessLevel: null,
				}),
				createdByUserId: this.user.id,
				triggerWord: null,
				name,
				description: null,
				...(chosenRandomAvatar ? {
					avatarId: chosenRandomAvatar.id,
					avatar: chosenRandomAvatar,
				} : {}),
				architecture: MODEL_ARCHITECTURE.FLUX,
				type: MODEL_TYPE.LORA,
				file: null,
			};
		},
		async onAddModelClick () {
			const modelDraft = await this.createModelDraft();

			this.updateModelState({
				modelDraft,
				modelDialog: {
					...this.modelsState.modelDialog,
					open: true,
					mode: 'create',
				},
			});
		},
		async getRandomDefaultAvatar () {
			const avatars = [];
			try {
				const avatarsRef = db.collection('avatars')
					.where('deletedAt', '==', null)
					.where('userId', '==', 'DEFAULT')
					.where('useCase', '==', 'RUNNIT_LIB')
					.orderBy('name', 'asc');

				(await avatarsRef.get()).forEach(async (doc: any) => {
					avatars.push({
						...doc.data(),
						get id () { return doc.id },
					} as Avatar);
				});
			} catch (err) {
				console.error('failed to get default avatar images', err);
			}
			return avatars.length ? _sampleSize(avatars, 1)[0] : null;
		}
	},
});
