
import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import {
	CHECKOUT_USER_SUBSCRIPTION,
	CLUB_LIMIT_ADD_ON_INFO,
	CLUB_TOKEN_ADD_ON_INFO,
	CLUB_KEY,
} from '@run-diffusion/shared';
import ConfirmDialog from '@/components/base/ConfirmDialog.vue';
import { ROUTER } from '@/router/constants';

export default Vue.extend({
	name: 'BuyCreatorsClubButton',
	props: {
		btnProps: { type: Object, default: () => ({}) },
	},
	data () {
		return {
			isAnnual: false,
		};
	},
	computed: {
		...mapGetters([
			'showStripeUserMembershipPortalBtn',
			'clubOfferBenefits',
		]),
		...mapState([
			'user',
		]),
	},
	methods: {
		...mapActions([
			'updateSnackbar',
			'updateLoader',
			'goToStripeSubscriptionPortal',
		]),
		async onSignUpClubMembership (closeDialogCallback: Function) {
			if (this.user && this.user.clubOffer && typeof this.user.clubOffer !== 'string' && !this.user.clubOffer.isFreemium) {
				this.routerPush(this.$route, this.$router, {
					name: ROUTER.RUNNITS_SUBSCRIPTIONS,
					query: {
						showCcOptions: '1',
					},
				});
			} else {
				const routeData = this.$router.resolve({
					name: 'GoToPayment',
					query: {
						checkoutAction: CHECKOUT_USER_SUBSCRIPTION,
						club: CLUB_KEY.CC_001,
						...(this.isAnnual && {
							annual: '1',
						}),
						tokenClubAddOnId: CLUB_TOKEN_ADD_ON_INFO['TOKENRUNHOB-001'].id,
						limitClubAddOnId: CLUB_LIMIT_ADD_ON_INFO['LIMITRUNHOB-001'].id,
					},
				});
				window.open(routeData.href, '_blank');
			}
			if (closeDialogCallback) closeDialogCallback();
			this.$emit('on-close-upsell-dialog');
		},
	},
	components: {
		ConfirmDialog,
	},
});
