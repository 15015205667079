
import Vue from 'vue';
import { Session, SESSION_STATE } from '@run-diffusion/shared';
import { mapActions } from 'vuex';
import { LaunchedSessionTimerMixin } from '@/mixins';
import ConfirmDialog from '@/components/base/ConfirmDialog.vue';
import ExtendSessionTimerDialog from '@/components/Toolbar/SessionTimer/ExtendSessionTimerDialog.vue';

export default Vue.extend({
	name: 'SessionTimerBtns',
	mixins: [
		LaunchedSessionTimerMixin,
	],
	props: {
		waitForStoppingSession: { type: Boolean, default: true },
	},
	// TODO: When the session stops, we should show a dialog explaining why
	data () {
		return {
			SESSION_STATE,

			stoppingSession: false,
			stopSessionDialogTitle: 'Stop Session',
			stopSessionDialogBodyText: 'Shortly after this session is shutdown, the unused time will be returned to your account balance. Are you sure you want to stop this session?',
			stopSessionDialogConfirmText: 'Yes, Stop Session',
			stopSessionDialogCancelText: 'Close',
		};
	},
	computed: {
		showExtendBtn () {
			const CUT_OFF_IN_MINUTES: number = 60 * 30;
			return !!(
				this.launchedSession &&
				(
					this.launchedSession.purchasePrice > 0 ||
					this.countDownTimerData.seconds < CUT_OFF_IN_MINUTES
				)
			);
		},
		sessionTimerBtnsDisabled () {
			return !!(
				this.stoppingSession ||
				!this.launchedSession ||
				this.launchedSession.state !== SESSION_STATE.READY
			);
		},
	},
	methods: {
		...mapActions([
			'stopSession',
		]),
		async doStopSession (session: Session, closeAndResetCallback: Function) {
			if (session) {
				this.stoppingSession = true;

				if (this.waitForStoppingSession) {
					const { success } = await this.stopSession(session);
					if (!success) {
						// Allow to try again
						this.stoppingSession = false;
					}
				} else {
					this.stopSession(session);
					this.stoppingSession = false;
				}
			}
			if (closeAndResetCallback) {
				closeAndResetCallback();
			}
		},
	},
	components: {
		ExtendSessionTimerDialog,
		ConfirmDialog,
	},
});
