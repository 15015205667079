
import Vue from 'vue';
import { db } from "@/firebase";
import { RUNNIT_NODE_DEF_TAG_TYPE, RUNNIT_TYPE, RunnitNodeDef, RunnitNodeDefTag } from "@run-diffusion/shared";
import { RunnitsCRUDMixin } from "@/mixins";
import { SNACKBAR_STATUS } from "@/constants/constants";
import TrainModelCard from "@/views/Runnits/pages/models/TrainModelCard.vue";
import { mapActions, mapState } from "vuex";
import { ModelCRUDMixin } from "@/mixins/ModelCRUDMixin";
import EmptyState from '@/components/states/EmptyState.vue';
import GetSupportBtn from "@/components/Toolbar/GetSupportBtn/GetSupportBtn.vue";
import ActionsIslandFixedBottom from "@/components/ActionsIslandFixedBottom.vue";
import {
	TRAINING_QUALITY_CARDS,
	TRAINING_TYPE_CARDS,
} from "@/views/Runnits/constants";
import _sortBy from 'lodash/sortBy';

export default Vue.extend({
	name: 'RunnitTrainModelOptions',
	mixins: [
		RunnitsCRUDMixin,
		ModelCRUDMixin,
	],
	data () {
		return {
			selectedTypeTagId: null,
			selectedQualityTagId: null,
			nodeDefIdSelected: null,
			imagePaths: {
				MmXNBoWAcBdwRNV2EDGq: ['popeye1.jpg', 'popeye2.jpg', 'popeye3.jpg', 'popeye4.jpg'],
				cJOrWSRNYwt6szmO8MF5: ['undraw1.jpg', 'undraw2.jpg', 'undraw3.jpg', 'undraw4.jpg'],
				eb0OsnA83NYtQO7lVuhH: ['iphone1.jpg', 'iphone2.jpg', 'iphone3.jpg', 'iphone4.jpg'],
			},
			trainingTypeTags: [],
			trainingQualityTags: [],
			TRAINING_QUALITY_CARDS,
			TRAINING_TYPE_CARDS,
			trainingOptionStep: 1,
		};
	},
	computed: {
		...mapState([
			'loadingTrainerNodeDefs',
			'trainerNodeDefs',
			'publicRunnitNodeDefTagsMap',
			'loadingPublicRunnitNodeDefTags'
		]),
		computedActionIslandWidth () {
			let width: string = '96.5%';

			if (this.$vuetify.breakpoint.smOnly) {
				width = '50%';
			}

			if (this.$vuetify.breakpoint.mdOnly) {
				width = '35%';
			}

			if (this.$vuetify.breakpoint.lgAndUp) {
				width = '25%';
			}

			return width;
		},
	},
	watch: {
		trainerNodeDefs: {
			immediate: true,
			handler (newVal: RunnitNodeDef[], oldVal: RunnitNodeDef[]) {
				if (newVal !== oldVal) {
					this.initTrainingTypeTagSelections(newVal);
				}
			},
		},
		selectedTypeTagId: {
			immediate: true,
			handler (newVal: string, oldVal: string) {
				if (newVal !== oldVal) {
					this.initTrainingQualityTagSelections(this.trainerNodeDefs, newVal);
				}
			},
		},
	},
	methods: {
		...mapActions([
			'updateSnackbar',
		]),
		initTrainingTypeTagSelections (trainerNodeDefs: RunnitNodeDef[]) {
			const trainingTypeTagIdsSet: Set<string> = new Set();
			(trainerNodeDefs || []).forEach((nodeDef: RunnitNodeDef) => {
				const tagIds: string[] = Object.keys(nodeDef.tags || {});
				tagIds.forEach((tagId: string) => {
					const tag: RunnitNodeDefTag = this.publicRunnitNodeDefTagsMap[tagId] || null;
					if (tag && tag.type === RUNNIT_NODE_DEF_TAG_TYPE.TRAINING_TYPE) {
						trainingTypeTagIdsSet.add(tagId);
					}
				});
			});

			this.trainingTypeTags = _sortBy(
				Array.from(trainingTypeTagIdsSet).map((tagId: string) => this.publicRunnitNodeDefTagsMap[tagId]),
				'sortOrder',
			);

			// Auto-selecting logic
			if (
				this.$vuetify.breakpoint.mdAndUp &&
				(
					!this.selectedTypeTagId ||
					!trainingTypeTagIdsSet.has(this.selectedTypeTagId)
				)
			) {
				const autoSelectTypeTag: RunnitNodeDefTag = this._get(this.trainingTypeTags, '[0]') || null;
				if (
					autoSelectTypeTag &&
					TRAINING_TYPE_CARDS[autoSelectTypeTag.id] &&
					!TRAINING_TYPE_CARDS[autoSelectTypeTag.id].comingSoon
				) {
					this.setTypeSelected(autoSelectTypeTag.id);
				} else {
					this.setTypeSelected(null);
				}
			}
		},
		initTrainingQualityTagSelections (trainerNodeDefs: RunnitNodeDef[], selectedTypeTagId: string) {
			const trainingQualityTagIdsSet: Set<string> = new Set();
			(trainerNodeDefs || []).forEach((nodeDef: RunnitNodeDef) => {
				if (!this._get(nodeDef.tags, selectedTypeTagId)) return; // Filter to only trainer tools that have the selected training type tag

				const tagIds: string[] = Object.keys(nodeDef.tags || {});
				tagIds.forEach((tagId: string) => {
					const tag: RunnitNodeDefTag = this.publicRunnitNodeDefTagsMap[tagId] || null;
					if (tag && tag.type === RUNNIT_NODE_DEF_TAG_TYPE.TRAINING_QUALITY) {
						trainingQualityTagIdsSet.add(tagId);
					}
				});
			});

			this.trainingQualityTags = _sortBy(
				Array.from(trainingQualityTagIdsSet).map((tagId: string) => this.publicRunnitNodeDefTagsMap[tagId]),
				'sortOrder',
			);

			// Auto-selecting logic
			if (
				this.$vuetify.breakpoint.mdAndUp &&
				(
					!this.selectedQualityTagId ||
					!trainingQualityTagIdsSet.has(this.selectedQualityTagId)
				)
			) {
				const autoSelectQualityTag: RunnitNodeDefTag = this._get(this.trainingQualityTags, '[0]') || null;
				if (
					autoSelectQualityTag &&
					TRAINING_QUALITY_CARDS[autoSelectQualityTag.id] &&
					!TRAINING_QUALITY_CARDS[autoSelectQualityTag.id].comingSoon
				) {
					this.setQualitySelected(autoSelectQualityTag.id);
				} else {
					this.setQualitySelected(null);
				}
			}
		},
		setTypeSelected (tagId: string) {
			this.selectedTypeTagId = null;
			this.selectedQualityTagId = null;
			if (tagId)  {
				this.trainingOptionStep = 2;
				this.selectedTypeTagId = tagId;
				this.selectedQualityTagId = null;
			}
		},
		setQualitySelected (tagId: string) {
			this.selectedQualityTagId = null;
			this.nodeDefIdSelected = null;
			if (tagId) {
				this.trainingOptionStep = 3;
				this.selectedQualityTagId = tagId
				const nodeDefSelected: RunnitNodeDef = this.trainerNodeDefs.find((nodeDef: RunnitNodeDef) => (
					nodeDef.tags[this.selectedTypeTagId] &&
					nodeDef.tags[this.selectedQualityTagId])
				) || null;
				this.nodeDefIdSelected = nodeDefSelected ? nodeDefSelected.id : null;
			}
		},
		async goTrain () {
			if (!this.nodeDefIdSelected) {
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Please select a trainer to use',
					show: true,
				});
				return;
			}

			let query = db.doc(`runnitNodeDefs/${this.nodeDefIdSelected}`);
			const runnitNodeDef = await query.get().then(doc => ({
				id: doc.id,
				...doc.data()
			}));
			await this.onAddRunnitClick(runnitNodeDef, RUNNIT_TYPE.SINGLE_TOOL);
		},
	},
	components: {
		ActionsIslandFixedBottom,
		GetSupportBtn,
		TrainModelCard,
		EmptyState,
	},
});
