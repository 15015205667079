
import Vue from 'vue';

export default Vue.extend({
	name: 'LoadingState',
	props: {
		hideDisplayText: { type: Boolean, default: false },
		size: { type: Number, default: 60 },
		width: { type: Number, default: 2 },
		color: { type: String, default: '#666666' },
		textStyles: { type: [String, Object], default: 'font-size: 19px' },
		containerStyles: { type: [String, Object], default: 'margin-top: 48px; margin-bottom: 48px; padding-top: 48px; padding-bottom: 48px;' },
		progress: { type: Number, default: 0 },
	},
});
