import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';
import App = firebase.app.App;
import Firestore = firebase.firestore.Firestore;
import Functions = firebase.functions.Functions;
import Storage = firebase.storage.Storage;

const firebaseConfig: Record<string, string> = {
	apiKey: 'AIzaSyDHaMc5CQCEbEjaP0ptHRrhVO3av0LPqO0',
	authDomain: 'image-ai-cb35d.firebaseapp.com',
	projectId: 'image-ai-cb35d',
	storageBucket: 'image-ai-cb35d.appspot.com',
	messagingSenderId: '2322932302',
	appId: '1:2322932302:web:d1d7af0a99e44e6f66d91f',
	measurementId: 'G-49LJPE2E1R',
	databaseURL: "https://image-ai-cb35d-default-rtdb.firebaseio.com",
};

const firebaseApp: App = firebase.initializeApp(firebaseConfig);

export const db: Firestore = firebaseApp.firestore();
export const functions: Functions = firebaseApp.functions();
export const storage: Storage = firebaseApp.storage();
export const storageRunnit1: Storage = firebaseApp.storage('gs://runnit-1');
