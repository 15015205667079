
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import { EraserBrush } from '@erase2d/fabric'; // It looks like erasing isn't part of the default build so we'd need to host our own fabric http://fabricjs.com/erasing
import { Canvas, Image as FabricImage, PencilBrush, Object as FabricObject } from 'fabric';
import BaseSlider from '@/components/base/BaseSlider.vue';
import GlassButton from '@/components/base/GlassButton.vue';
import { SNACKBAR_STATUS } from '@/constants/constants';
import { RunnitMask, RUNNIT_MASK_USE_CASE, UPLOAD_FILE_TYPE } from '@run-diffusion/shared';
import { db, storage } from '@/firebase';
import { v4 as uuidv4 } from 'uuid';
import DialogOrBottomSheet from '@/components/base/DialogOrBottomSheet.vue';
import ComplexBackground from '@/components/designElements/ComplexBackground.vue';
import { ImageUploadMixin } from '@/mixins/ImageUploadMixin';
import BackgroundPrimaryColorSVG from '@/assets/BackgroundPrimaryColorSVG.vue';
import { SetColorFilter } from './SetColorFilter';

export default Vue.extend({
	name: 'InpaintingEditor',
	props: {
		maskURL: { type: String, default: null },
		nodeId: { type: String, default: null },
	},
	mixins: [
		ImageUploadMixin,
	],
	data () {
		return {
			SELECTION: {
				BRUSH: 'BRUSH',
				ERASER: 'ERASER',
			},
			STATIC_HEIGHT: 550,
			STATIC_WIDTH: 550,
			MOBILE_STATIC_HEIGHT: 300,
			MOBILE_STATIC_WIDTH: 300,
			LARGE_STATIC_HEIGHT: 800,
			LARGE_STATIC_WIDTH: 800,
			LIGHT_COLOR: 'rgba(255, 255, 255, 0.75)',
			DARK_COLOR: 'rgba(0, 0, 0, 0.75)',

			isDrawing: false,
			brushSize: 30,
			selection: 'BRUSH',
			isLightBrushColor: true,
			canvas: null as Canvas | null,
			history: [] as FabricObject[],
			eraserBrush: null as EraserBrush | null,
			pencilBrush: null as PencilBrush | null,

			hasResizeListener: false,
			imageWidth: 0,
			imageHeight: 0,
			canvasWidth: 550,
			canvasHeight: 550,
		};
	},
	computed: {
		...mapState([
			'runnitState',
			'user',
		]),
		colorToggle () {
			if (this.isLightBrushColor) {
				return {
					background: '#000000',
					backgroundBorder: '#000000',
					primary: '#FFFFFF',
					primaryBorder: '#000000',
				}
			}
			return {
				background: '#FFFFFF',
				backgroundBorder: '#000000',
				primary: '#000000',
				primaryBorder: '#000000',
			}
		}
	},
	watch: {
		brushSize: {
			handler (newSize: number) {
				if (this.canvas) {
					this.canvas.freeDrawingBrush.width = newSize;
				}
			},
		},
		'runnitState.inpaintingEditorOpen': {
			immediate: true,
			async handler (newVal) {
				if (newVal) {
					this.initCanvas();
				}
			}
		}
	},
	methods: {
		...mapActions([
			'updateRunnitState',
			'updateSnackbar',
		]),
		initCanvas () {
			this.destroyCanvas();

			this.setCanvasHeight();
			this.setCanvasWidth();

			this.canvas = new Canvas('brush-canvas', {
				width: this.canvasWidth,
				height: this.canvasHeight,
				isDrawingMode: true,
			});

			if (this.canvas) {
				// Create the brush size cursor
				this.canvas.defaultCursor = 'none';

				// Update cursor position during general mouse movement
				// If we are drawing we don't need to draw the cursor circle
				this.canvas.on('mouse:move', (event) => {
					if (!this.isDrawing) {
						this.drawCursor(event);
					}
				});

				// Clear cursor circle when mouse leaves canvas
				this.canvas.on('mouse:out', () => {
					this.canvas.contextTop.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
				});

				this.canvas.on('mouse:down', () => {
					this.isDrawing = true;
				});

				this.canvas.on('mouse:up', (event) => {
					this.isDrawing = false;
					this.drawCursor(event);
				});

				this.canvas.renderAll();

				if (this.maskURL) {
					FabricImage.fromURL(
						this.maskURL,
						{
							crossOrigin: 'anonymous',
						}
					).then((img) => {
						if (this.isBlackAndWhiteMask(img)) {
							this.onDeleteInpaintingMask()
						} else {
							if (this.canvas) {
								if (this.isLandscape()) {
									img.scaleToWidth(this.canvasWidth)
								} else {
									img.scaleToHeight(this.canvasHeight)
								}
								img.filters = [
									new SetColorFilter({ color: this.LIGHT_COLOR }),
								]
								img.applyFilters();
								this.canvas.add(img);
								this.canvas.renderAll();
							}
						}
					})
				}
			}

			this.setupBrushes();

			this.canvas.on('object:added', this.onObjectAdded);

			if (!this.hasResizeListener) {
				window.addEventListener('resize', this.handleResize);
				this.hasResizeListener = true;
			}
		},
		isBlackAndWhiteMask (img: FabricImage) {
			const tempCanvas = document.createElement('canvas');
			const tempCtx = tempCanvas.getContext('2d');

			tempCanvas.width = img.width;
			tempCanvas.height = img.height;

			const imageElemet = img.getElement();
			tempCtx.drawImage(imageElemet, 0, 0);

			const imageData = tempCtx.getImageData(0, 0, tempCanvas.width, tempCanvas.height);
			const pixels = imageData.data;

			let hasWhite = false;
			let hasBlack = false;

			for (let i = 0; i < pixels.length; i += 4) {
				const [r, g, b, a] = [pixels[i], pixels[i + 1], pixels[i + 2], pixels[i + 3]];
				if (r === 255 && g === 255 && b === 255 && a === 255) {
					hasWhite = true;
				}

				if (r === 0 && g === 0 && b === 0 && a === 255) {
					hasBlack = true;
				}

				if (hasWhite && hasBlack) {
					break;
				}
			}

			return hasWhite && hasBlack;
		},
		drawCursor (event) {
			const pointer = this.canvas.getPointer(event.e);
			this.canvas.contextTop.clearRect(0, 0, this.canvas.width, this.canvas.height);

			this.canvas.contextTop.beginPath();
			this.canvas.contextTop.arc(pointer.x, pointer.y, this.brushSize / 2, 0, Math.PI * 2, false);
			this.canvas.contextTop.strokeStyle = 'white';
			this.canvas.contextTop.lineWidth = 2;
			this.canvas.contextTop.stroke();
		},
		switchBrushColor (isLightBrushColor) {
			this.isLightBrushColor = isLightBrushColor;
			if (isLightBrushColor) {
				this.canvas.freeDrawingBrush.color = this.LIGHT_COLOR;
				this.convertAllDrawingToColor(this.LIGHT_COLOR)
			} else {
				this.canvas.freeDrawingBrush.color = this.DARK_COLOR;
				this.convertAllDrawingToColor(this.DARK_COLOR)
			}
			this.canvas.renderAll();
		},
		setupBrushes () {
			if (this.canvas) {
				this.pencilBrush = new PencilBrush(this.canvas);
				this.pencilBrush.width = this.brushSize;
				if (this.isLightBrushColor) {
					this.pencilBrush.color = this.LIGHT_COLOR;
				} else {
					this.pencilBrush.color = this.DARK_COLOR;
				}
				this.canvas.renderAll();

				this.eraserBrush = new EraserBrush(this.canvas);
				this.eraserBrush.width = this.brushSize;

				this.canvas.freeDrawingBrush = this.pencilBrush;
			}
		},
		onDialogOpenStateChange (isOpen) {
			if (!isOpen) {
				this.onClose();
			}
		},
		onClose () {
			this.destroyCanvas();
			this.updateRunnitState({
				inpaintingEditorOpen: false,
			});
		},
		async handleDone () {
			try {
				await this.updateRunnitState({
					inpaintingEditorSaving: true,
				});

				if (this.canvas) {
					const scaleFactor = this.imageWidth / this.canvasWidth;

					if (this.canvas.getObjects().length < 1) {
						// Empty mask, no need to save it
						this.$emit('input', null);
						this.onClose();
						return;
					}

					try {
						// Convert fabric.js canvas to HTMLCanvasElement
						const htmlCanvas = this.canvas.toCanvasElement();

						// Create a temporary canvas element to resize content
						const tempCanvas = document.createElement('canvas');
						tempCanvas.width = this.imageWidth;
						tempCanvas.height = this.imageHeight;
						const tempContext = tempCanvas.getContext('2d');

						// Draw the original canvas content onto the temporary canvas
						tempContext.drawImage(
							htmlCanvas,
							0, 0, htmlCanvas.width, htmlCanvas.height, // Source dimensions
							0, 0, tempCanvas.width, tempCanvas.height // Destination dimensions
						);

						// Convert the resized canvas to a data URL
						const dataURL = tempCanvas.toDataURL('image/png');

						const maskFile = await this.srcToFile(dataURL, `${uuidv4()}.png`, 'png');

						const savedMask = await this.uploadMaskToFirebase(maskFile);

						this.$emit('input', savedMask);
					} finally {
						this.canvas.backgroundColor = null;
						this.canvas.renderAll();
					}
				}
				this.onClose();

			} catch (err) {
				console.error('Error saving inpainting mask', err);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error saving inpainting mask',
					show: true,
				});
			} finally {
				await this.updateRunnitState({
					inpaintingEditorSaving: false,
				});
			}
		},
		async srcToFile (src, fileName, mimeType) {
			return (fetch(src)
				.then((res) => { return res.arrayBuffer(); })
				.then((buf) => { return new File([buf], fileName, { type: mimeType }) })
			);
		},
		async uploadMaskToFirebase (file) {
			let mask: RunnitMask = null;

			// Calculate hash of the file data
			const fileData = await file.arrayBuffer();
			const hashBuffer = await crypto.subtle.digest('SHA-256', fileData);
			const hashArray = Array.from(new Uint8Array(hashBuffer));
			const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');

			// Check if hash exists already
			const masksRef = db.collection('runnitMasks')
				.where('hashHex', '==', hashHex)
				.where('userId', '==', this.user.id)
				.where('deletedAt', '==', null)
				.where('useCase', '==', 'INPAINTING')
				.limit(1);
			(await masksRef.get()).forEach(async (doc: any) => {
				mask = {
					...doc.data(),
					get id () { return doc.id },
				} as RunnitMask;
			});

			if (!mask) {
				// mask file
				const { name, contentType, size, width, height } = await this.getImageMetaData(file);
				const fileRef = storage.ref(`runnitMasks/users/${this.user.id}/masks/${name}`);

				await fileRef.put(file); // acts like an upsert

				// Record mask file in database
				const nowDate: Date = new Date();
				await db.collection('runnitMasks')
				const addedMaskRef = await db.collection('runnitMasks')
					.add({
						createdAt: nowDate,
						deletedAt: null,
						userId: this.user.id,
						teamId: null,
						type: UPLOAD_FILE_TYPE.IMG,
						name,
						contentType,
						size,
						width,
						height,
						hashHex,
						useCase: RUNNIT_MASK_USE_CASE.INPAINTING,
					});
				mask = {
					...(await addedMaskRef.get()).data(),
					get id () { return addedMaskRef.id },
				} as RunnitMask;
			}

			return mask;
		},
		onSelection (selection: string) {
			this.selection = selection;
			if (this.canvas) {
				if (selection === this.SELECTION.ERASER) {
					this.canvas.freeDrawingBrush = this.eraserBrush;
				} else {
					this.canvas.freeDrawingBrush = this.pencilBrush;
				}
			}
		},
		isSelected (selection: string) {
			return this.selection === selection;
		},
		onStepBack () {
			if (this.history.length > 0 && this.canvas) {
				const lastAction = this.history.pop();
				if (lastAction) {
					this.canvas.remove(lastAction);
					this.canvas.renderAll();
				}
			}
		},
		onDeleteInpaintingMask () {
			if (this.canvas) {
				this.history = [];
				this.canvas.clear();
			}
		},
		handleResize () {
			if (this.canvas) {
				this.canvas.setDimensions({
					width: this.canvasWidth,
					height: this.canvasHeight,
				});
				this.canvas.renderAll();
			}
		},
		onObjectAdded (e: { target: FabricObject }) {
			this.history.push(e.target);
		},
		destroyCanvas () {
			if (this.canvas) {
				this.canvas.off('object:added', this.onObjectAdded);
				this.canvas.dispose();
			}
		},
		actionsCardDragStart (event) {
			const style = window.getComputedStyle(event.target, null);
			event.dataTransfer.setData("text/plain",
				(parseInt(style.getPropertyValue("left"), 10) - event.clientX) + ',' + (parseInt(style.getPropertyValue("top"), 10) - event.clientY)
			);
		},
		actionsCardDragEnd (event) {
			const offset = event.dataTransfer.getData("text/plain").split(',');
			const dm = document.getElementById('actionsCardDraggable');
			dm.style.left = (event.clientX + parseInt(offset[0], 10)) + 'px';
			dm.style.top = (event.clientY + parseInt(offset[1], 10)) + 'px';
			event.preventDefault();
			return false;
		},
		dragOver (event) {
			event.preventDefault;
			return false;
		},
		aspectRatio () {
			if (this.imageWidth && this.imageHeight) {
				return this.imageWidth / this.imageHeight;
			}
			return 1;
		},
		isLandscape () {
			return this.aspectRatio() >= 1;
		},
		setCanvasHeight (): number {
			const height = this.$vuetify.breakpoint.xsOnly ? this.MOBILE_STATIC_HEIGHT :
				this.$vuetify.breakpoint.lgAndUp ? this.LARGE_STATIC_HEIGHT : this.STATIC_HEIGHT
			this.canvasHeight = this.isLandscape() ? height / this.aspectRatio() : height;
			return this.canvasHeight;
		},
		setCanvasWidth (): number {
			const width = this.$vuetify.breakpoint.xsOnly ? this.MOBILE_STATIC_WIDTH :
				this.$vuetify.breakpoint.lgAndUp ? this.LARGE_STATIC_WIDTH : this.STATIC_WIDTH
			this.canvasWidth = this.isLandscape() ? width : width * this.aspectRatio();
			return this.canvasWidth;
		},
		imageLoaded (event) {
			this.imageWidth = this.$refs.imageRef.naturalWidth;
			this.imageHeight = this.$refs.imageRef.naturalHeight;
			this.initCanvas();
		},
		async convertAllDrawingToColor (color: string) {
			this.canvas.getObjects().forEach(obj => {
				if (obj.type === 'path' || obj.type === 'line') {
					obj.set({
						stroke: color,
					});
				}
				else {
					obj.set({
						filters: [
							new SetColorFilter({ color }),
						]
					});
					obj.applyFilters();
				}
			});
		},
	},
	beforeDestroy () {
		this.hasResizeListener = false;
		window.removeEventListener('resize', this.handleResize);
		this.destroyCanvas();
	},
	components: {
		BaseSlider,
		GlassButton,
		ComplexBackground,
		DialogOrBottomSheet,
		BackgroundPrimaryColorSVG,
	},
});
