
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import { RunnitDynamicFieldMixin } from '@/views/Runnits/RunnitSettings/mixins/RunnitDynamicFieldMixin';
import RunnitModelsDialog from '../base/RunnitModelsDialog.vue';
import RunnitImage from "@/views/Runnits/RunnitImage.vue";

export default Vue.extend({
	name: 'RunnitModelSelector',
	mixins: [
		RunnitDynamicFieldMixin,
	],
	props: {
		value: { type: [Object], default: null },
	},
	data () {
		return {
			NUMBER_IMAGES: 8,

			open: false,
		};
	},
	computed: {
		...mapState([]),
	},
	methods: {
		...mapActions([]),
		onOpenDialog () {
			this.open = true;
		},
		onModelSelect (model) {
			this.$emit('input', {model});

		},
	},
	components: {
		RunnitImage,
		RunnitModelsDialog,
	},
});
