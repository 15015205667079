
import Vue from 'vue';
import RunnitImage from "@/views/Runnits/RunnitImage.vue";

export default Vue.extend({
	name: 'RunnitModelSelection',
	props: {
		value: { type: Object, default: null },
	},
	data () {
		return {
			NUMBER_IMAGES: 8,
		};
	},
	methods: {
		onSelection (index: number) {
			this.$emit('input', index);
		},
	},
	components: {
		RunnitImage
	},
});
