
import Vue from 'vue';
import {
	RunnitNodeDefTagType,
	READABLE_RUNNIT_NODE_DEF_TAG_TYPE,
	RUNNIT_NODE_DEF_TAG_TYPE,
	RUNNIT_NODE_DEF_TAG_TYPE_ICON,
} from '@run-diffusion/shared';
import { RUNNITS_OWNER_SELECTION } from './constants';
import { mapState } from 'vuex';

export default Vue.extend({
	name: 'RunnitTagTypeSelect',
	props: {
		selectedTypes: { type: Array, default: () => [] },
		multiSelect: { type: Boolean, default: false },
	},
	data () {
		return {
			RUNNIT_NODE_DEF_TAG_TYPE,
		};
	},
	watch: {
		selectedTypes: {
			immediate: true,
			handler (newVal) {
				if (!newVal.length) {
					this.onSelectAll();
				}
			}
		}
	},
	computed: {
		...mapState([
			'runnitState',
			'team',
		]),
		items () {
			return Object.values(RUNNIT_NODE_DEF_TAG_TYPE).filter((tagType: RunnitNodeDefTagType) => {
				const excludedTypes: RunnitNodeDefTagType[] = [
					RUNNIT_NODE_DEF_TAG_TYPE.HOME_PAGE,
					RUNNIT_NODE_DEF_TAG_TYPE.INDUSTRY,
					RUNNIT_NODE_DEF_TAG_TYPE.TRAINING_TYPE,
					RUNNIT_NODE_DEF_TAG_TYPE.TRAINING_QUALITY,
				];
				if (this.runnitState.runnitsOwnerSelection === RUNNITS_OWNER_SELECTION.USER || !this.team) {
					excludedTypes.push(RUNNIT_NODE_DEF_TAG_TYPE.TEAM);
				}
				return !excludedTypes.includes(tagType);
			}).map((t: RunnitNodeDefTagType, i: number) => {
				return {
					id: i,
					label: READABLE_RUNNIT_NODE_DEF_TAG_TYPE[t],
					tagType: t,
					icon: RUNNIT_NODE_DEF_TAG_TYPE_ICON[t]
				}
			})
		},
		selectedTypesMap () {
			return this.selectedTypes.reduce((map: Record<RunnitNodeDefTagType, boolean>, tagType: RunnitNodeDefTagType) => ({
				...map,
				[tagType]: true,
			}), {});
		},
		isSelectAllFilledIn () {
			const allSelected = this.items.length <= this.selectedTypes.length;
			this.$emit('is-all-selected', allSelected);
			return allSelected;
		},
	},
	methods: {
		onSelectAll () {
			this.$emit(
				'on-select',
				this.items.map(({ tagType }) => tagType).filter((tagType) => (
					tagType !== RUNNIT_NODE_DEF_TAG_TYPE.TEAM ||
					(this.runnitState.runnitsOwnerSelection === RUNNITS_OWNER_SELECTION.TEAM && this.team)
				)),
			);
		},
		handleOptionSelection (selectedItem) {
			let selectedTypes: RunnitNodeDefTagType[] = [...this.selectedTypes];

			if (this.isSelectAllFilledIn) {
				selectedTypes = [];
			}

			if (this.multiSelect) {
				if (selectedTypes.includes(selectedItem.tagType)) {
					selectedTypes = selectedTypes.filter((tagType: RunnitNodeDefTagType) => tagType !== selectedItem.tagType);
				} else {
					selectedTypes.push(selectedItem.tagType);
				}
			} else {
				selectedTypes = [selectedItem.tagType];
			}

			if (!selectedTypes.length) {
				this.onSelectAll();
			} else {
				this.$emit('on-select', selectedTypes);
			}
		},
	},
});
