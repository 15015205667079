
import Vue from 'vue';
import { ROUTER } from '@/router/constants';
import { mapActions, mapGetters, mapState } from 'vuex';
import { RunnitsCRUDMixin } from '@/mixins';
import { setRunnitsTeamAccessLevel } from '@/utils';
import { RUNNIT_TYPE, RUNNITS_ACCESS_LEVEL } from '@run-diffusion/shared';
import { RUNNITS_OWNER_SELECTION } from '@/views/Runnits/constants';
import RunnitTokens from '@/views/Runnits/RunnitTokens.vue';
import RunnitAccountSelectionDialog from '@/views/Runnits/RunnitAccountSelectionDialog.vue';
import RunnitsVerticalLogoSVG from '@/assets/RunnitsVerticalLogoSVG.vue';
import ComplexBackgroundSideNav from '@/views/Runnits/RunnitSideNav/ComplexBackgroundSideNav.vue';
import LoadingState from "@/components/states/LoadingState.vue";

export default Vue.extend({
	name: 'RunnitSideNav',
	mixins: [
		RunnitsCRUDMixin,
	],
	props: {},
	data () {
		return {
			ROUTER,
			RUNNITS_OWNER_SELECTION,
		};
	},
	computed: {
		...mapState([
			'runnitState',
		]),
		...mapGetters('modelUpload', [
			'getUploading',
		]),
		isSubscriptionsRoute () {
			return this.$route.name === ROUTER.RUNNITS_SUBSCRIPTIONS;
		},
		isHomeRoute () {
			return this.$route.name === ROUTER.RUNNITS_HOME;
		},
		isModelsRoute () {
			return this.$route.name === ROUTER.RUNNITS_MODELS_LIST;
		},
		isAllToolsRoute () {
			return this.$route.name === ROUTER.RUNNITS_ALL_TOOLS;
		},
		// isLibraryRoute () {
		// 	return this.$route.name === ROUTER.RUNNITS_LIBRARY;
		// },
		isPrivateRoute () {
			return this.$route.name === ROUTER.RUNNITS_PRIVATE;
		},
		isSharedRoute () {
			return this.$route.name === ROUTER.RUNNITS_SHARED;
		},
	},
	methods: {
		...mapActions([
			'updateRunnitState',
			'updateToolbar',
		]),
		goTo (routeName: string) {
			this.routerPush(this.$route, this.$router, { name: routeName });
		},
		async onPrivateClick () {
			setRunnitsTeamAccessLevel(RUNNITS_ACCESS_LEVEL.PRIVATE);
			await this.updateRunnitState({
				runnitsAccessLevel: RUNNITS_ACCESS_LEVEL.PRIVATE,
			});
			this.goTo(ROUTER.RUNNITS_PRIVATE);
		},
		async onSharedClick () {
			setRunnitsTeamAccessLevel(RUNNITS_ACCESS_LEVEL.SHARED);
			await this.updateRunnitState({
				runnitsAccessLevel: RUNNITS_ACCESS_LEVEL.SHARED,
			});
			this.goTo(ROUTER.RUNNITS_SHARED);
		},
		async onApexClick () {
			const runnitNodeDefWithId = {
				id: 'mzttaygaC3NXy6qOerIn',
				title: 'Runnit Apex',
			};
			await this.onAddRunnitClick(runnitNodeDefWithId, RUNNIT_TYPE.SINGLE_TOOL);
		},
		async onFluxProClick () {
			const runnitNodeDefWithId = {
				id: 'G2Rixspe3TIRFLn7BM8j',
				title: 'Flux 1.1 Pro',
			};
			await this.onAddRunnitClick(runnitNodeDefWithId, RUNNIT_TYPE.SINGLE_TOOL);
		},
		async onInpaintingClick () {
			const runnitNodeDefWithId = {
				id: 'tlKpuSBUXDqgucOjXcLk',
				title: 'Inpainting',
			};
			await this.onAddRunnitClick(runnitNodeDefWithId, RUNNIT_TYPE.SINGLE_TOOL);
		},
		async onCreativeUpscalerClick () {
			const runnitNodeDefWithId = {
				id: 'n4H8sQlLxTc1aeYJxdiv',
				title: 'Creative Upscaler',
			};
			await this.onAddRunnitClick(runnitNodeDefWithId, RUNNIT_TYPE.SINGLE_TOOL);
		},
		openRunDiffusionInNewTab () {
			const routeData = this.$router.resolve({
				name: 'Sessions',
			});
			window.open(routeData.href, '_blank');
		},
		closeSideNav () {
			this.updateToolbar({
				runnitsSideNavOpen: false,
			});
		},
	},
	components: {
		LoadingState,
		ComplexBackgroundSideNav,
		RunnitsVerticalLogoSVG,
		RunnitTokens,
		RunnitAccountSelectionDialog,
	},
});
