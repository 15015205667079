
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import ModelSelect from '@/components/ModelSelect/ModelSelect.vue';
import { ToolbarState } from '@/store';
import { SESSION_STATE } from '@run-diffusion/shared';

export default Vue.extend({
	name: 'SessionBar',
	props: {
		hideMultipleSessions: { type: Boolean, default: false },
	},
	computed: {
		...mapState([
			'user',
			'toolbar',
			'appOffersMap',
			'currSessionBackgroundTasks',
		]),
		showServerBuddyBtn () {
			return !!(
				this.toolbar.session &&
				(
					this.toolbar.session.state === SESSION_STATE.READY ||
					(this.user && this.user.isAdmin)
				)
			);
		},
		disabledServerBuddyBtn () {
			return !(
				this.toolbar.session &&
				this.toolbar.sbudServices.sessionId === this.toolbar.session.id &&
				(
					this.toolbar.sbudServices.restart ||
					this.toolbar.sbudServices.getLogs
				)
			);
		},
		showCivitaiDownloadBtn () {
			return !!(
				this.toolbar.session &&
				(
					this.toolbar.session.state === SESSION_STATE.READY ||
					(this.user && this.user.isAdmin)
				)
			);
		},
		disabledCivitaiDownloadBtn () {
			return !(
				this.toolbar.session &&
				this.toolbar.sbudServices.sessionId === this.toolbar.session.id &&
				this.toolbar.sbudServices.downloadCivitaiModels
			);
		},
		showResourcesBtn () {
			const appOffer = this.appOffersMap[this._get(this.toolbar, 'session.apps[0]')];
			return !!this._get(appOffer, 'resources.length', 0);
		},
	},
	watch: {
		toolbar: {
			immediate: true,
			handler (newVal: ToolbarState, oldVal: ToolbarState) {
				if (
					newVal &&
					newVal !== oldVal &&
					newVal.session &&
					newVal.session.id !== this._get(oldVal, 'session.id', null)
				) {
					this.bindCurrSessionBackgroundTasks(newVal.session);
				}
			}
		},
	},
	methods: {
		...mapActions([
			'updateToolbar',
			'bindCurrSessionBackgroundTasks',
		]),
		onCloseSessionBar () {
			this.updateToolbar({
				sessionBarOpen: false,
			});
		},
		onServerBuddyToggle () {
			this.updateToolbar({
				serverBuddyOpen: !this.toolbar.serverBuddyOpen,
			});
		},
		onMultiSessionClick () {
			this.updateToolbar({
				settingsOpen: !this.toolbar.settingsOpen,
				mobileMenuOpen: false,
			});
		},
		onToggleSessionBarCollapsed () {
			this.updateToolbar({
				sessionBarCollapsed: !this.toolbar.sessionBarCollapsed,
			});
		},
		onOpenResourcesDialog () {
			this.updateToolbar({
				resourcesDialog: {
					open: true,
					appOffer: this.toolbar.session.appOffer,
				},
			});
		},
	},
	components: {
		ModelSelect,
	},
});
