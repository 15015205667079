
import Vue from 'vue';
import RunnitDialog from './RunnitDialog.vue';
import LoadingState from '@/components/states/LoadingState.vue';
import {THUMB_SIZE, RUNNITS_ACCESS_LEVEL} from '@run-diffusion/shared';
import {mapActions, mapState} from "vuex";
import RunnitNavTabs from "@/views/Runnits/base/RunnitNavTabs.vue";
import {RUNNITS_OWNER_SELECTION} from "@/views/Runnits/constants";
import EmptyState from "@/components/states/EmptyState.vue";
import ModelCard from "@/components/ModelCard.vue";
import {db} from "@/firebase";
import {get$bindFirestoreOptions} from "@/mixins";
import {SNACKBAR_STATUS} from "@/constants/constants";

export default Vue.extend({
	name: 'RunnitModelsDialog',
	props: {
		elIdSuffix: { type: String, required: true }, // to prefix html element IDs in the DOM
        value: { type: Boolean, default: false },
        bodyHeight: { type: String, default: '80vh' },
		maxWidth: { type: [Number, String], default: '80%' },
		persistent: { type: Boolean, default: false },
	},
	data () {
		return {
			RUNNITS_ACCESS_LEVEL,
            THUMB_SIZE,
			open: false,
			currNavTab: RUNNITS_ACCESS_LEVEL.PRIVATE,

			loadingModels: false,
			models: [],
		};
	},
	created () {
		this.open = !!this.value;
	},
	computed: {
		...mapState([
			'user',
			'team',
			'runnitState',
			'modelsState'
		]),
		modelsNavTabs() {
			const hasTeam = this.runnitState.runnitsOwnerSelection === RUNNITS_OWNER_SELECTION.TEAM && this.team?.id;

			const tabs = [{
				id: RUNNITS_ACCESS_LEVEL.PRIVATE,
				label: 'Your Models',
				icon: 'mdi-vector-circle',
			},];

			if (hasTeam) {
				tabs.push({
					id: RUNNITS_ACCESS_LEVEL.SHARED,
					label: 'Team Models',
					icon: 'mdi-account-group-outline',
				},)
			}

			return tabs;
		},
	},
	watch: {
		value (newVal: boolean) {
            this.setOpen(newVal);
		},
		currNavTab: {
			immediate: true,
			async handler(newTabId) {
				try {
					this.loadingModels = true;
					if (this.team.id && this.user.id) {
						// index created: models - teamId Ascending accessLevel Ascending isDeleted Ascending createdAt Descending __name__ Descending
						const modelsRef: any = db.collection(`models`)
							.where('teamId', '==', this.team.id)
							.where('accessLevel', '==', newTabId)
							.where('isDeleted', '==', false)
							.orderBy('createdAt', 'desc')
							.limit(500);
						await this.$bind(
							'models',
							modelsRef,
							get$bindFirestoreOptions(),
						);
					} else if (this.user.id) {
						// index created: models - userId Ascending teamId Ascending accessLevel Ascending isDeleted Ascending createdAt Descending __name__ Descending
						const modelsRef: any = db.collection(`models`)
							.where('userId', '==', this.user.id)
							.where('teamId', '==', null)
							.where('accessLevel', '==', newTabId)
							.where('isDeleted', '==', false)
							.orderBy('createdAt', 'desc')
							.limit(100);
						await this.$bind(
							'models',
							modelsRef,
							get$bindFirestoreOptions(),
						);
					}

				} catch (e) {
					console.error(e);
					this.updateSnackbar({
						status: SNACKBAR_STATUS.ERROR,
						message: 'Error loading models',
						show: true,
					});
				} finally {
					this.loadingModels = false;
				}
			},
		},
	},
	methods: {
		...mapActions([
			'updateModelState',
		]),
		setOpen (val: boolean) {
			this.open = !!val;
			if (this.open !== this.value) {
				this.$emit('input', this.open);
			}
		},
		onCancel () {
			this.setOpen(false);
		},
        onModelSelect (model) {
            this.$emit('on-model-select', model);
            this.setOpen(false);
        },
		changeNavTab (tabId) {
			this.currNavTab = tabId;
			this.$emit('on-nav-tab-change', tabId);
		},
		onEditModelSettings(model) {
			if (model) {
				this.updateModelState({
					modelDraft: model,
					modelDialog: {
						...this.modelsState.modelDialog,
						open: true,
						mode: 'edit',
					},
				});
			}
		}
	},
	components: {
		ModelCard,
		EmptyState,
		RunnitNavTabs,
        RunnitDialog,
        LoadingState,
	},
});
