import { VBtn } from 'vuetify/lib/components/VBtn';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"number-with-incrementer"},[_c('div',{staticClass:"number-with-incrementer-selection",style:({
			color: _vm.showMaxWarning ? 'var(--red-400)' : 'var(--grey-200)',
			width: _vm.condensed ? 'min-content' : '100%',
		})},[_c('span',{style:({ 'min-width': _vm.numberMinWidth })},[_vm._v(_vm._s(_vm.value))]),_c(VExpandTransition,[(_vm.showMaxWarning)?_c('div',{staticClass:"number-with-incrementer-selection-max"},[_vm._v(" MAX ")]):_vm._e()])],1),_c('div',{staticClass:"number-with-incrementer-controls"},[_c(VBtn,{staticStyle:{"top":"2px"},attrs:{"x-small":"","icon":"","color":"primary","disabled":_vm.disabled},on:{"click":function($event){return _vm.onIncrement(true)}}},[_c(VIcon,{staticStyle:{"font-size":"23px","color":"var(--grey-500)"}},[_vm._v("mdi-chevron-up")])],1),_c(VBtn,{staticStyle:{"top":"-2px"},attrs:{"x-small":"","icon":"","color":"primary","disabled":_vm.disabled},on:{"click":function($event){return _vm.onIncrement(false)}}},[_c(VIcon,{staticStyle:{"font-size":"23px","color":"var(--grey-500)"}},[_vm._v("mdi-chevron-down")])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }