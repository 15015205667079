import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.modelsState.modelDraft)?_c('div',[_c(VTextField,{attrs:{"label":"Title","filled":"","rounded":"","counter":"100","maxlength":"100","rules":[v => !!_vm._trim(v) || 'Title is required']},model:{value:(_vm.modelsState.modelDraft.name),callback:function ($$v) {_vm.$set(_vm.modelsState.modelDraft, "name", $$v)},expression:"modelsState.modelDraft.name"}}),_c(VTextarea,{style:({width: '100%'}),attrs:{"label":"Description","placeholder":"Put an optional description here...","filled":"","rounded":"","auto-grow":"","rows":"3","counter":"1000","maxlength":"1000","hide-details":"auto"},model:{value:(_vm.modelsState.modelDraft.description),callback:function ($$v) {_vm.$set(_vm.modelsState.modelDraft, "description", $$v)},expression:"modelsState.modelDraft.description"}}),_c(VRadioGroup,{staticStyle:{"margin-top":"0"},attrs:{"mandatory":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticStyle:{"color":"var(--grey-600)"}},[_vm._v("Model Access Setting")])]},proxy:true}],null,false,540409045),model:{value:(_vm.modelsState.modelDraft.accessLevel),callback:function ($$v) {_vm.$set(_vm.modelsState.modelDraft, "accessLevel", $$v)},expression:"modelsState.modelDraft.accessLevel"}},[_c(VRadio,{attrs:{"label":"Private (Only you can view)","color":"primary","value":_vm.RUNNITS_ACCESS_LEVEL.PRIVATE}}),((_vm.runnitState.runnit && _vm.runnitState.runnit.teamId) ||
			(_vm.runnitState.runnitsOwnerSelection === _vm.RUNNITS_OWNER_SELECTION.TEAM))?_c(VRadio,{attrs:{"label":"Shared (Every team member can view)","color":"primary","value":_vm.RUNNITS_ACCESS_LEVEL.SHARED}}):_vm._e()],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }