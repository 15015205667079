
import Vue from 'vue';
import { mapState } from 'vuex';
import { ANNOUNCEMENT_TYPE } from '@run-diffusion/shared';
import {
	getAnnouncementsDismissed,
	setAnnouncementDismissed,
} from '@/utils/localStorage';

export default Vue.extend({
	name: 'AnnouncementsBanner',
	props: {
		announcement: { type: Object, required: true },
	},
	data () {
		return {
			ANNOUNCEMENT_TYPE,
			dismissed: false,
		};
	},
	created () {
		this.initDismissed(this.announcement);
	},
	watch: {
		announcement (newVal) {
			this.initDismissed(newVal);
		},
	},
	computed: {
		...mapState([
			'user',
			'toolbar',
		]),
		isExpired () {
			return !!(
				this.announcement.expiresAt &&
				this.announcement.expiresAt.toDate() < new Date()
			);
		},
	},
	methods: {
		initDismissed (announcement) {
			const dismissedAnnouncements = getAnnouncementsDismissed(this.user.id);
			const isDismissed = dismissedAnnouncements.includes(announcement.message);

			if (isDismissed) {
				this.dismissed = true;
			} else {
				this.dismissed = false;
			}
		},
		dismissAnnouncement (announcement) {
			setAnnouncementDismissed(announcement.message, this.user.id);
			this.dismissed = true;
		},
	},
});
