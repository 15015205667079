import {SessionState, TeamRole, SESSION_STATE, TEAM_ROLE} from '@run-diffusion/shared';

export const TEXT_FIELD_MAX_LENGTH: number = 2500;
export const SHOW_TEXT_FIELD_COUNTER_AT: number = 450;
export const TEXT_FIELD_RULE: ((v: string) => boolean | string) = (v: any): boolean | string => (
	!v || v.length <= TEXT_FIELD_MAX_LENGTH || `Must be less than ${TEXT_FIELD_MAX_LENGTH} characters`
);
export const SERVER_ACTIONS = {
	REBOOT: 'rebootsd',
};
export const NEW_USER_PROMO_START_DATE: string = '2024-01-20T15:53:00Z'; // Saturday 8:53am
export const NEW_USER_PROMO_END_DATE: string = '2024-01-22T06:55:00Z'; // Sunday 11:55pm

export const EMAIL_REGEX: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape

// Google Analytics
export const GTAG_EVENTS: {
	ON_ADD_FUNDS_CLICK: string
} = {
	ON_ADD_FUNDS_CLICK: 'ON_ADD_FUNDS_CLICK',
};
export const GOOGLE_ANALYTICS_UID: string = 'G-0LR2KJKD3M';

export const SNACKBAR_STATUS: {
	ERROR: string
	SUCCESS: string
	WARN: string
	INFO: string
} = {
	ERROR: 'ERROR',
	SUCCESS: 'SUCCESS',
	WARN: 'WARN',
	INFO: 'INFO',
};


export const SELECTED_STORAGE_TYPE: {
	PRIVATE: string
	TEAM: string
} = {
	PRIVATE: 'PRIVATE',
	TEAM: 'TEAM',
};

interface TeamRoleDetails {
	id: TeamRole
	label: string
	description: string
}
export const TEAM_ROLE_DETAILS: {
	ADMIN: TeamRoleDetails
	CREATOR: TeamRoleDetails
} = {
	ADMIN: {
		id: TEAM_ROLE.ADMIN,
		label: 'Admin',
		description: 'Admins can add other team members',
	},
	CREATOR: {
		id: TEAM_ROLE.CREATOR,
		label: 'Creator',
		description: 'Creators can only generate images using either their team or personal storage',
	},
};

export const BOOTING_SESSION_STATES: SessionState[] = [
	SESSION_STATE.PURCHASE,
	SESSION_STATE.INIT,
	SESSION_STATE.BOOT,
];
export const READY_SESSION_STATES: SessionState[] = [
	SESSION_STATE.READY,
	SESSION_STATE.EXTEND,
];
export const STOPPING_SESSION_STATES: SessionState[] = [
	SESSION_STATE.STOP,
	SESSION_STATE.RECONCILE,
];
export const CURR_SESSION_STATES: SessionState[] = [
	...BOOTING_SESSION_STATES,
	...READY_SESSION_STATES,
	...STOPPING_SESSION_STATES,
];
export const PAST_SESSION_STATES: SessionState[] = [
	SESSION_STATE.STOPPED,
];
export const UPSELL_TYPE: {
	TEAM: string
	CREATORS_CLUB: string
} = {
	TEAM: 'TEAM',
	CREATORS_CLUB: 'CREATORS_CLUB',
};
export const TRAFFIC_SOURCE: {
	REDDIT: string
	YOUTUBE: string
	GOOGLE: string
	TIKTOK: string
	TWITTER: string
	INSTAGRAM: string
	DISCORD: string
	CIVITAI: string
	OTHER: string
} = {
	REDDIT: 'REDDIT',
	YOUTUBE: 'YOUTUBE',
	GOOGLE: 'GOOGLE',
	TIKTOK: 'TIKTOK',
	TWITTER: 'TWITTER',
	INSTAGRAM: 'INSTAGRAM',
	DISCORD: 'DISCORD',
	CIVITAI: 'CIVITAI',
	OTHER: 'OTHER',
};

export const REPORTS: {
	QUESTIONS: {},
	DASHBOARDS: {
		TEAM_METRICS_DASHBOARD: number
		IMAGE_AUDIT_DASHBOARD: number
		USER_USAGE_REPORT: number
	},
} = {
	QUESTIONS: {},
	DASHBOARDS: {
		TEAM_METRICS_DASHBOARD: 9,
		IMAGE_AUDIT_DASHBOARD: 35,
		USER_USAGE_REPORT: 36,
	},
};

export type RunnitsPublishedState = 'PUBLISHED' |
	'NOT_PUBLISHED'
	;
export const RUNNITS_PUBLISHED_STATE: Record<RunnitsPublishedState, RunnitsPublishedState> = {
	PUBLISHED: 'PUBLISHED',
	NOT_PUBLISHED: 'NOT_PUBLISHED',
};

export type PortalLocation =
	'TRAINER_IMAGE_UPLOAD'
	;
export const PORTAL_LOCATION: Record<PortalLocation, PortalLocation> = {
	TRAINER_IMAGE_UPLOAD: 'TRAINER_IMAGE_UPLOAD',
}
