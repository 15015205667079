
import Vue from 'vue';
import _truncate from 'lodash/truncate';
import _isNil from 'lodash/isNil';
import { SETTING_ACTIONS } from '@/views/Runnits/constants';
import {
	RunnitNodeField,
	RunnitMask,
	getLogicOnFieldsFromField,
	RUNNIT_NODE_FIELD_TYPE,
	RUNNIT_NODE_STATIC_FIELDS_KEY,
} from '@run-diffusion/shared';
import RunnitImage from '@/views/Runnits/RunnitImage.vue';
import RunnitModelSelection from '@/views/Runnits/RunnitSettings/RunnitModelSelection.vue';
import ImageInfoMaskPreview from '@/components/ImageGallery/ImageInfoMaskPreview.vue';
import { mapState } from 'vuex';

export default Vue.extend({
	name: 'ImageInfoFields',
	props: {
		fields: { type: Array, required: true },
		nodeRun: { type: Object, required: true },
		insideDrawer: { type: Boolean, default: false },
		insideNode: { type: Boolean, default: false },
		insideCarousel: { type: Boolean, default: false },
		ignoreTruncation: { type: Boolean, default: false },
		index: { type: [String, Number], default: null },
		imageAttributionStyles: { type: Object, default: null },
	},
	data () {
		return {
			SETTING_ACTIONS,
			RUNNIT_NODE_FIELD_TYPE,

			showMask: true,
			expandedGroups: {},
		};
	},
	watch: {
		fields: {
			immediate: true,
			handler (newVal) {
				if (newVal && newVal.length) {
					this.expandedGroups = newVal.reduce((expandedMap, field) => {
						if (field.__rgroup) {
							expandedMap[field.uuid] = true;
						}
						return expandedMap;
					}, {});
				}
			}
		}
	},
	computed: {
		...mapState([
			'user',
		]),
		numImgs () {
			return this._get(this.nodeRun, 'results.length') || this._get(this.nodeRun, `staticInputs[${RUNNIT_NODE_STATIC_FIELDS_KEY.numResults}]`) || 1;
		},
		isFirstIndex () {
			return +this.index === 0;
		},
		fieldValueTruncateCharCount () {
			let charCount: number = 400;
			if (this.$vuetify.breakpoint.xsOnly || this.insideDrawer) {
				charCount = 100;
			}
			if (this.ignoreTruncation) {
				charCount = 3000;
			}
			return charCount;
		},
		firstLetterOfEmail () {
			return this.nodeRun.email ? this.nodeRun.email[0].toUpperCase() : '';
		},
		fieldsThatShouldDisplay () {
			return this.fields.filter((field) => this.shouldFieldBeDisplayed(field));
		}
	},
	methods: {
		_truncate,
		_isNil,
		determineWidth () {
			let width = '100%';
			if (
				this.$vuetify.breakpoint.smAndUp &&
				!this.insideDrawer
			) {
				width = '50%';
			}
			return width;
		},
		getInpaintingMask (field: RunnitNodeField): RunnitMask {
			return (
				field &&
				field.type === RUNNIT_NODE_FIELD_TYPE.IMG &&
				this.nodeRun.inputs[field.fieldDefUuid] &&
				this.nodeRun.inputs[field.fieldDefUuid].inpainting &&
				this.nodeRun.inputs[field.fieldDefUuid].inpainting.mask
			) || null;
		},
		toggleGroupExpansion (uuid) {
			this.expandedGroups[uuid] = !this.expandedGroups[uuid];
		},
		shouldFieldBeDisplayed (field) {
			const {
				allDisables,
				allShows,
				triggeredDisables,
				triggeredShows,
			} = getLogicOnFieldsFromField(field, this.nodeRun.inputs);
			const isShown: boolean = !!(!allShows.length || triggeredShows.length);
			const isDisabled: boolean = !!(isShown && triggeredDisables.length);
			const hideAdmin: boolean = field.adminOnly && !this.user.isAdmin
			return isShown && !isDisabled && !hideAdmin;
		}
	},
	components: {
		ImageInfoMaskPreview,
		RunnitModelSelection,
		RunnitImage,
	},
});
