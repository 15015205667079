
import Vue from 'vue';
import { STOP_CODE } from '@run-diffusion/shared';
import InfoMessage from '@/components/InfoMessage.vue';
import SessionInfoCard from '@/components/SessionInfoCard.vue';

export default Vue.extend({
	name: 'StoppingSessionDialog',
	props: {
		value: { type: Boolean, default: false },
		session: { type: Object, default: null },
	},
	data () {
		return {
			STOP_CODE,
		};
	},
	components: {
		SessionInfoCard,
		InfoMessage,
	},
});
