
import Vue from 'vue';
import { db } from '@/firebase';
import { mapState } from 'vuex';
import { Survey, SURVEY_KEY } from '@run-diffusion/shared';
import { TRAFFIC_SOURCE } from '@/constants/constants';
import WindowStepper from '@/components/WindowStepper.vue';
import JoinOurDiscord from '@/components/Toolbar/JoinOurDiscord.vue';
import DiscordSVG from '@/assets/DiscordSVG.vue';
import { get$bindFirestoreOptions } from '@/mixins';

export default Vue.extend({
	name: 'NewUserSurvey',
	data () {
		let windowItemIter: number = 0;
		return {
			TRAFFIC_SOURCE,

			windowView: 0,
			QUESTION_1_WINDOW: windowItemIter++,
			QUESTION_2_WINDOW: windowItemIter++,
			QUESTION_3_WINDOW: windowItemIter++,
			POST_SURVEY_WINDOW: windowItemIter++,
			WINDOW_VIEW_LENGTH: windowItemIter,
			largestWindowView: 0,

			newIntroSurveyId: null,
			existingIntroSurvey: null,
			existingIntroSurveyLoading: false,
			familiarRating: 0,
			proficientRating: 0,
			trafficSource: null,
			trafficSourceDetails: null,
			showTrafficSourceDetails: false,

			// FORM
			formValid: true,
			rules: {
				required: [
					v => !!v || 'Required field',
				],
			},
		};
	},
	async created () {
		if (this.user.introSurveyId) {
			try {
				this.existingIntroSurveyLoading = true;
				const surveyRef = db.doc(`surveys/${this.user.introSurveyId}`);
				const survey: Survey = await this.$bind(
					'existingIntroSurvey',
					surveyRef,
					get$bindFirestoreOptions(),
				);
				this.familiarRating = this._get(survey, 'fields.familiarRating.value') || 0;
				this.proficientRating = this._get(survey, 'fields.proficientRating.value') || 0;
				this.trafficSource = this._get(survey, 'fields.trafficSource.value') || null;
				this.trafficSourceDetails = this._get(survey, 'fields.trafficSourceDetails.value') || null;
				if (this.trafficSourceDetails) {
					this.showTrafficSourceDetails = true;
				}
			} catch (e) {
				console.error(e);
			} finally {
				this.existingIntroSurveyLoading = false;
			}
		}
	},
	watch: {
		windowView: {
			immediate: true,
			handler (newVal: number) {
				this.largestWindowView = Math.max(this.largestWindowView, newVal);
			},
		},
	},
	computed: {
		...mapState([
			'user',
		]),
		continueDisabled () {
			return !!(
				!!(
					this.windowView === this.QUESTION_1_WINDOW &&
					!this.familiarRating
				) ||
				!!(
					this.windowView === this.QUESTION_2_WINDOW &&
					!this.proficientRating
				)
			);
		},
		finishDisabled () {
			return !!(
				this.windowView === this.lastQuestionView &&
				!this.trafficSource
			);
		},
		lastQuestionView () {
			return this.QUESTION_3_WINDOW;
		},
		trafficSourceDetailsLabel () {
			if (this.trafficSource === TRAFFIC_SOURCE.YOUTUBE) {
				return 'Which youtuber?';
			} else if (this.trafficSource === TRAFFIC_SOURCE.TIKTOK) {
				return 'Which tiktoker?';
			} else if (this.trafficSource === TRAFFIC_SOURCE.TWITTER) {
				return 'Who on Twitter?';
			} else if (this.trafficSource === TRAFFIC_SOURCE.INSTAGRAM) {
				return 'Who on Instagram?';
			} else if (this.trafficSource === TRAFFIC_SOURCE.DISCORD) {
				return 'Which Discord Server?';
			}

			return 'How did you hear about us?';
		},
	},
	methods: {
		onTrafficSourceClick (trafficSource) {
			if (
				![
					TRAFFIC_SOURCE.REDDIT,
					TRAFFIC_SOURCE.GOOGLE,
					TRAFFIC_SOURCE.CIVITAI,
				].includes(trafficSource)
			) {
				this.showTrafficSourceDetails = true;
			} else {
				this.showTrafficSourceDetails = false;
				this.trafficSourceDetails = null;
			}

			this.trafficSource = trafficSource;
		},
		onWindowViewBack () {
			if (this.windowView > 0) {
				this.windowView--;
			}
		},
		async onWindowViewForward () {
			if (this.windowView < this.lastQuestionView) {
				this.windowView++;
				await this.upsertSurvey(false);
			}
		},
		async onFinish () {
			this.$emit('on-finish');
			await this.upsertSurvey(true);
			this.windowView++;
		},
		async upsertSurvey (isFinished) {
			const surveyId: string = this.user.introSurveyId ||
				this.newIntroSurveyId ||
				db.collection('surveys').doc().id;
			const userRef = db.doc(`users/${this.user.id}`);

			if (!this.user.introSurveyId) {
				await userRef.update({
					introSurveyId: surveyId,
				});
			}

			if (isFinished) {
				await userRef.update({
					introSurveyCompletedAt: new Date(),
				});
			}

			const surveyRef = db.doc(`surveys/${surveyId}`);
			await surveyRef.set({
				createdAt: this.existingIntroSurvey ? this.existingIntroSurvey.createdAt : new Date(),
				key: SURVEY_KEY.NEW_USER_1,
				userId: this.user.id,
				fields: {
					...(this.familiarRating && {
						familiarRating: {
							label: 'How familiar are you with open source AI tools with Stable Diffusion?',
							type: 'NUMBER',
							value: this.familiarRating,
						},
					}),
					...(this.proficientRating && {
						proficientRating: {
							label: 'How proficient are you with open source AI tools with Stable Diffusion?',
							type: 'NUMBER',
							value: this.proficientRating,
						},
					}),
					...(this.trafficSource && {
						trafficSource: {
							label: 'Where did you find us?',
							type: 'TEXT',
							value: this.trafficSource,
						},
					}),
					...(this.trafficSourceDetails && {
						trafficSourceDetails: {
							label: this.trafficSourceDetailsLabel,
							type: 'TEXT',
							value: this.trafficSourceDetails,
						},
					}),
				},
			});
		},
	},
	components: {
		DiscordSVG,
		JoinOurDiscord,
		WindowStepper,
	},
});
