
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import JoinOurDiscord from '@/components/Toolbar/JoinOurDiscord.vue';
import { SESSION_STATE } from '@run-diffusion/shared';
import { mapActions } from 'vuex';
import ConfirmDialog from '@/components/base/ConfirmDialog.vue';
import InfoMessage from '@/components/InfoMessage.vue';
import NewUserSurvey from '@/components/NewUserSurvey/NewUserSurvey.vue';
import SessionInfoCard from '@/components/SessionInfoCard.vue';
import UserUsage1Survey from '@/components/UserUsage1Survey/UserUsage1Survey.vue';
import PreloadModelInstructions from '@/components/DownloadModelInstructions/PreloadModelInstructions.vue';
import PreloadModelPreview from '@/components/PreloadModelPreview.vue';

export default Vue.extend({
	name: 'BootingSessionScreen',
	props: {
		session: { type: Object, default: null },
	},
	data () {
		return {
			showPreloadModelInstructions: false,
			stoppingSession: false,
			justFinishedNewUserSurvey: false,
			justFinishedUserUsage1Survey: false,
		};
	},
	mounted () {
		if (this.isNewUserTrialFlow && this._get(this.preloadModel, 'civitai.model')) {
			// initialize open, if it's their first time
			this.showPreloadModelInstructions = true;
		}
	},
	computed: {
		...mapState([
			'user',
			'authUser',
			'preloadModel',
		]),
		...mapGetters([
			'isNewUserTrialFlow',
		]),
		preloadModelIsSessionPreloadModel () {
			return !!(
				this.preloadModel.civitai &&
				this.preloadModel.civitai.model &&
				this.session &&
				this.session.preloadModel &&
				this.session.preloadModel.modelId === this.preloadModel.civitai.model.id
			);
		},
		surveysNotComplete () {
			return !!(
				this.introSurveyNotComplete ||
				this.justFinishedNewUserSurvey ||
				this.userUsage1SurveyNotComplete ||
				this.justFinishedUserUsage1Survey
			);
		},
		introSurveyNotComplete () {
			return !!(
				!this._get(this.user, 'introSurveyCompletedAt') ||
				this.justFinishedNewUserSurvey
			);
		},
		usage1SurveyTime () {
			const month: number = 30 * 24 * 60 * 60 * 1000; // 1 MONTH
			const twoWeeks: number = 14 * 24 * 60 * 60 * 1000; // 2 WEEKS
			const oneWeek: number = 7 * 24 * 60 * 60 * 1000; // 1 WEEK

			return twoWeeks;
		},
		userUsage1SurveyNotComplete () {
			const usageTimeToSurvey: number = Date.now() - this.usage1SurveyTime;
			const userHasEnoughUsageToDoSurvey: boolean = this.user.createdAt.toMillis() < usageTimeToSurvey;

			return !!(
				userHasEnoughUsageToDoSurvey &&
				!!(
					!this._get(this.user, 'usage1SurveyCompletedAt') ||
					this.justFinishedUserUsage1Survey
				)
			);
		},
		stepperVal () {
			if (this.session) {
				if (this.session.bootAt) {
					return 3;
				}
				if (this.session.readyAt) {
					return 4;
				}
			}
			return 2;
		},
		hasRetriedInitAtLeastOnce () {
			return this._get(this.session, 'retries.retryInitAt');
		},
		isCancellable () {
			return !!(
				this.session &&
				this.session.serverName && // Session needs the serverName stored in order to cancel it
				(
					this.session.state === SESSION_STATE.BOOT ||
					(
						this.session.state === SESSION_STATE.INIT &&
						this.hasRetriedInitAtLeastOnce
					)
				)
			);
		},
	},
	methods: {
		...mapActions([
			'stopSession',
		]),
		async cancelSession () {
			if (!this.session || !this.isCancellable) return;

			this.stoppingSession = true;
			const { success } = await this.stopSession(this.session);
			if (!success) {
				// Allow to try again
				this.stoppingSession = false;
			}
		},
	},
	components: {
		PreloadModelPreview,
		PreloadModelInstructions,
		UserUsage1Survey,
		SessionInfoCard,
		NewUserSurvey,
		InfoMessage,
		ConfirmDialog,
		JoinOurDiscord,
	},
});
