import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.isNsfwPlaceholder)?_c('div',{staticClass:"nsfw-container",on:{"click":_vm.onClick}},[_c('img',{staticClass:"nsfw-image",attrs:{"src":require("../../assets/blurry-silhouette-512x512.png")}}),_c(VExpandTransition,[_c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('div',{staticClass:"overlay-container",style:({
                        height: hover ? '90%' : '55px',
                    })},[_c('div',{staticClass:"overlay-title"},[_c(VIcon,{staticStyle:{"color":"var(--primary-blue)","font-size":"17px","margin":"0 4px 0 0","position":"relative","top":"-1.5px"}},[_vm._v("mdi-cancel")]),_vm._v(" NSFW (beta) ")],1),_c('div',{staticClass:"overlay-description"},[_vm._v(" Our system for flagging images in Runnits as NSFW is currently a work-in progress, please have patience if it makes mistakes as we improve it. ")])])]}}],null,false,1433901455)})],1)],1):(_vm.nodeRunResult && _vm.nodeRunResult.type === 'VID')?_c('runnit-video',{attrs:{"node-run-result":_vm.nodeRunResult,"is-thumb":_vm.isThumb,"no-click":_vm.noClick},on:{"on-click":_vm.onClick,"on-single-select":_vm.onSingleSelect,"on-multi-select":_vm.onMultiSelect}}):_c('runnit-image',{attrs:{"timer-start-at-millis":_vm.timerStartAtMillis,"node-run-result":_vm.nodeRunResult,"upload":_vm.upload,"avatar":_vm.avatar,"no-click":_vm.noClick,"is-thumb":_vm.isThumb,"is-avatar":_vm.isAvatar,"clearable":_vm.clearable,"width":_vm.width,"height":_vm.height,"is-error-placeholder":_vm.isErrorPlaceholder,"is-loading-placeholder":_vm.isLoadingPlaceholder,"is-inside-info-carousel":_vm.isInsideInfoCarousel,"is-selected-image":_vm.isSelectedImage,"use-zoom-viewer":_vm.useZoomViewer,"cold-start-warning-seconds":_vm.coldStartWarningSeconds},on:{"on-click":_vm.onClick,"on-clear":_vm.onClear,"on-single-select":_vm.onSingleSelect,"on-multi-select":_vm.onMultiSelect}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }